import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import "./index.css"
import App from './App';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from "./pages/Error/index"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorPage} onReset={() => window.location.href = "/"}>
        <App />
      </ErrorBoundary>
    </BrowserRouter>   
  // </React.StrictMode>
);
