import React, { useEffect, useRef, useState } from "react";

import Chart from "react-apexcharts";

import bgLeft from "../../assets/bgLeftRectangle.png";
import bgPhoneTop from "../../assets/bgPhoneTopRectangle.png";
import profile from "../../assets/defaultPicture.png";
import daily from "../../assets/icons/daily.png";
import monthly from "../../assets/icons/monthly.png";

import { Link, useNavigate } from "react-router-dom";
import { userStore } from "../../store/user";

import ReactSpeedometer from "react-d3-speedometer";
import Accordion from "../../components/Accordion/Accordion";
import Sidebar from "../../components/Sidebar/Sidebar";
import Loader from "../../components/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import { getChannelsOfServer } from "../../API/discord";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Statistics = () => {
  const navigate = useNavigate();
  const [bigNavToggle, setbigNavToggle] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const chartRef = useRef(null);
  // const [btoData, setBtoData] = useState()
  const [isLoading, setIsLoading] = useState(true);
  const [btoStoreData, setBtoStoreData] = useState();
  const [btoStoreFilteredData, setBtoStoreFilteredData] = useState();
  const [btoApiData, setBtoApiData] = useState();
  const [initialPnlData, setInitialPnLData] = useState([]);
  const [initialPerData, setInitialPerData] = useState([]);
  const [graphPnlData, setGraphPnlData] = useState();
  const [graphPerData, setGraphPerData] = useState();
  const [graphTimeScale, setGraphTimeScale] = useState("week");
  const [pnlChartType, setPnlChartType] = useState("percentage");
  const [pnlMeterType, setPnlMeterType] = useState("percentage");
  const [minValueX, setminValueX] = useState(null);
  const [maxValueX, setmaxValueX] = useState(null);
  const [maxValueY, setmaxValueY] = useState(0);
  const [minValueY, setminValueY] = useState(0);

  // const [minPerX, setminPerX] = useState(null);
  // const [maxPerX, setmaxPerX] = useState(null);
  const [maxPerY, setmaxPerY] = useState(0);
  const [minPerY, setminPerY] = useState(0);

  const [meterTimeScale, setMeterTimeScale] = useState("daily");
  const [netPnl, setNetPnl] = useState();
  const [netPer, setNetPer] = useState();

  // const [weekPnlAdjustment, setWeekPnlAdjustment] = useState(0);
  // const [monthPnlAdjustment, setMonthPnlAdjustment] = useState(0);
  // const [yearPnlAdjustment, setYearPnlAdjustment] = useState(0);

  // const [weekPerAdjustment, setWeekPerAdjustment] = useState(0);
  // const [monthPerAdjustment, setMonthPerAdjustment] = useState(0);
  // const [yearPerAdjustment, setYearPerAdjustment] = useState(0);

  
  const [publishDailyStats, setPublishDailyStats] = useState(false);
  const [publishWeeklyStats, setPublishWeeklyStats] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);

  const [tableTimeScale, setTableTimeScale] = useState("week");

  // const [investedAmount, setInvestedAmount] = useState();
  // const [currentBalance, setCurrentBalance] = useState();

  const { userData } = userStore((state) => state);

  const formatter = new Intl.NumberFormat("en-US");

  // const now = new Date();

  const dateOptions = { day: "numeric", month: "long", year: "numeric" };

  const { btoData, socket } = userStore((state) => state);
  const gentradeToken = localStorage.getItem("gentradeToken");
  const subDomainUrl = localStorage.getItem('baseURL')

  const {
    avatarUrl,
    discordServers = [],
    name,
    id: userId,
    role,
    currentBalance
  } = userData || {};

  var options = {
    series: [
      {
        data: graphPnlData,
      },
    ],
    dataLabels: {
      enabled: true,
      offsetY: -12,
      style: {
        // colors: "#000000"
      },
      background: {
        enabled: true,
        foreColor: "#000000",
        opacity: 0.75,
        borderColor: "black",
      },
      formatter: function(value) {
        return "$" + value
      }
    },
    colors: ["#b8e834"],
    chart: {
      id: "datetime",
      type: "line",
      // height: 350,
      zoom: {
        // autoScaleXaxis: true,
        // autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            height: "300px", // set the width to 100% for screens smaller than 480px
          },
        },
      },
    ],
    stroke: {
      width: 4,
      curve: "smooth",
    },
    markers: {
      size: 6,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      min: minValueX,
      max: maxValueX,
      // tickAmount: 6,
      axisBorder: {
        show: false,
        color: "#515151",
      },
      labels: {
        style: {
          colors: "#737B7B",
        },
      },
    },
    yaxis: {
      min: minValueY,
      max: maxValueY,
      tickAmount: 6,
      labels: {
        style: {
          colors: "#737B7B",
        },
      },
      // labels: {
      //   formatter: function (val) {
      //     return Math.round(val);
      //   },
      // },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: "#999",
          strokeDashArray: 0,
          position: "back",
          // label: {
          //   show: true,
          //   text: "Package Amount:" + selectedPackageAmount,
          //   position: "right",
          //   style: {
          //     cssClass: "apexcharts-point-annotation-label",
          //     color: "#fff",
          //     background: "blue",
          //   },
          // },
        },
      ],
    },
    tooltip: {
      theme: "dark",
      x: {
        format: "dd MMM yyyy",
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => "PnL = ",
        },
      },
    },
    grid: {
      show: true,
      borderColor: "#2B2B2B",
      strokeDashArray: 2,
      position: "back",
    },
  };

  var options2 = {
    series: [
      {
        data: graphPerData,
      },
    ],
    dataLabels: {
      enabled: true,
      offsetY: -12,
      style: {
        // colors: "#000000"
      },
      background: {
        enabled: true,
        foreColor: "#000000",
        opacity: 0.75,
        borderColor: "black",
      },
      formatter: function(value) {
        return value + "%" 
      }
    },
    colors: ["#b8e834"],
    chart: {
      id: "datetime",
      type: "line",
      // height: 350,
      zoom: {
        // autoScaleXaxis: true,
        // autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            height: "300px", // set the width to 100% for screens smaller than 480px
          },
        },
      },
    ],
    stroke: {
      width: 4,
      curve: "smooth",
    },
    markers: {
      size: 6,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      min: minValueX,
      max: maxValueX,
      // tickAmount: 6,
      axisBorder: {
        show: false,
        color: "#515151",
      },
      labels: {
        style: {
          colors: "#737B7B",
        },
      },
    },
    yaxis: {
      min: minPerY,
      max: maxPerY,
      tickAmount: 6,
      labels: {
        style: {
          colors: "#737B7B",
        },
      },
      // labels: {
      //   formatter: function (val) {
      //     return Math.round(val);
      //   },
      // },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: "#999",
          strokeDashArray: 0,
          position: "back",
          // label: {
          //   show: true,
          //   text: "Package Amount:" + selectedPackageAmount,
          //   position: "right",
          //   style: {
          //     cssClass: "apexcharts-point-annotation-label",
          //     color: "#fff",
          //     background: "blue",
          //   },
          // },
        },
      ],
    },
    tooltip: {
      theme: "dark",
      x: {
        format: "dd MMM yyyy",
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => "PnL% = ",
        },
      },
    },
    grid: {
      show: true,
      borderColor: "#2B2B2B",
      strokeDashArray: 2,
      position: "back",
    },
  };

  // useEffect(() => {

  // })

  // useEffect(() => {
  //   if (!socket) return;
  //   socket.on("daily_pnl_stream", (message) => {
  //     try {
  //       setBtoApiData(message);
  //     } catch (error) {
  //       console.error("Error parsing JSON or processing data:", error);
  //     }
  //   });
  // }, [socket]);

  useEffect(() => {
    // let { uid } = useParams();
    if (userData){
      // console.log(userData)
        const getUserHistory = async () => {
          axios({
            method: "get",
            url: `${process.env.REACT_APP_API_BASE_URL}/pnl/pnl_data/`,
            headers: {
              Authorization: "Bearer " + gentradeToken,
            },
            params: {
              userId: userData?.id,
            }
          })
            .then((response) => {
              // console.log(response.data.dailyNetPnls);
              // setBtoData(response.data.userBtos);
              setBtoApiData(response.data?.dailyNetPnlsData);
              // console.log(response.data?.dailyNetPnlsData)
            })
            .catch((error) => {
              console.log(error);
              toast("Something went wrong, please try again later", {
                type: "error",
              })
              navigate("/model")
            });
        };
        getUserHistory();
      }
  }, [userData]);

  // useEffect(() => {
  //   if (userData && currentBalance == undefined) {
  //     setInvestedAmount(userData?.investedAmount);
  //     setCurrentBalance(userData?.currentBalance);
  //   }
  // }, [userData]);

  // console.log(investedAmount, currentBalance)

  useEffect(() => {
    if (btoData && btoData?.active && btoData?.expired && !btoStoreData) {
      // console.log(btoData);
      let tempArray = btoData?.active?.map((item, index) => {
        const timestamp = new Date(item.created_at).getTime();
        return [timestamp, "active", item];
      });

      btoData?.expired?.map((item, index) => {
        const timestamp = new Date(item.created_at).getTime();
        tempArray = [...tempArray, [timestamp, "expired", item]];
      });

      btoData?.sold?.map((item, index) => {
        const timestamp = new Date(item.created_at).getTime();
        tempArray = [...tempArray, [timestamp, "sold", item]];
      });

      const currentDate = new Date().getTime();
      let lastSevenDaysArray = tempArray?.filter((entry) => {
        // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
        return currentDate - entry[0] <= 6 * 24 * 60 * 60 * 1000;
      });
      lastSevenDaysArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
      setBtoStoreFilteredData(lastSevenDaysArray);
      setBtoStoreData(tempArray);
    }
  }, [btoData]);

  useEffect(() => {
    if (btoApiData) {
      if (Object.keys(btoApiData).length > 0) {
        // console.log(btoApiData)
        let tempPnlArray = Object.keys(btoApiData)?.map((date) => {
          // console.log(parseFloat((btoApiData?.[date]).toFixed(2)))
          const timestamp = new Date(date)?.getTime();
          // return [timestamp, parseFloat((btoApiData?.[date]?.pnl)?.toFixed(2))];
          return [
            timestamp,
            parseFloat(Number(btoApiData?.[date]?.pnl ?? 0).toFixed(2)),
          ];
        });

        // console.log("tempPnlArray", tempPnlArray)

        let tempPerArray = Object.keys(btoApiData)?.map((date) => {
          // console.log(parseFloat((btoApiData?.[date]).toFixed(2)))
          const timestamp = new Date(date)?.getTime();
          // return [timestamp, parseFloat((btoApiData?.[date]?.pnlPercentage)?.toFixed(2))];
          return [
            timestamp,
            parseFloat(
              Number(btoApiData?.[date]?.pnlPercentage ?? 0).toFixed(2)
            ),
          ];
        });

        tempPnlArray.sort((a, b) => a[0] - b[0]);
        const minPnl = Math.ceil(
          tempPnlArray.reduce((min, arr) => Math.min(min, arr[1]), Infinity)
        );
        const maxPnl = Math.floor(
          tempPnlArray.reduce((max, arr) => Math.max(max, arr[1]), -Infinity)
        );
        const maxScale = Math.max(Math.abs(minPnl), Math.abs(maxPnl));

        setmaxValueY(isFinite(maxScale) && maxScale > 0 ? (maxScale * 1.25) : 200);
        setminValueY(isFinite(maxScale) && maxScale > 0 ? ((maxScale * -1) * 1.25) : -200);

        tempPerArray.sort((a, b) => a[0] - b[0]);
        // console.log(tempPerArray)
        const minPer = Math.ceil(
          tempPerArray.reduce((min, arr) => Math.min(min, arr[1]), Infinity)
        );
        const maxPer = Math.floor(
          tempPerArray.reduce((max, arr) => Math.max(max, arr[1]), -Infinity)
        );
        const maxPerScale = Math.max(Math.abs(minPer), Math.abs(maxPer));

        setmaxPerY(
          isFinite(maxPerScale) && maxPerScale > 0 ? (maxPerScale * 1.25) : 200
        );
        setminPerY(
          isFinite(maxPerScale) && maxPerScale > 0 ? ((maxPerScale * -1) * 1.25) : -200
        );

        setInitialPnLData(tempPnlArray);
        setInitialPerData(tempPerArray);

        // let currentArray = Object.keys(btoApiData)?.map((date) => {
        //   // console.log(parseFloat((btoApiData?.[date]).toFixed(2)))
        //   const timestamp = new Date(date)?.getTime();
        //   return [
        //     timestamp,
        //     parseFloat(btoApiData?.[date]?.currentBalance?.toFixed(2)),
        //   ];
        // });
        // currentArray.sort((a, b) => a[0] - b[0]);
        // setCurrentBalance(currentArray[currentArray.length - 1]?.[1]);
        // TODO: default case for week
        // eslint-disable-next-line default-case
        switch (graphTimeScale) {
          case "year":
            if (1) {
              const now = new Date();
              const minx = new Date(
                now.getFullYear() - 1,
                now.getMonth(),
                now.getDate() + 2
              ).getTime(); // Start from one year ago from today
              const maxx = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + 2
              ).getTime(); // End at one year ahead from today
              setmaxValueX(maxx);
              setminValueX(minx);
              chartRef?.current?.chart?.updateOptions({
                xaxis: {
                  type: "datetime",
                  min: minx,
                  max: maxx,
                },
              });

              const currentDate1 = new Date().getTime();
              let lastYearPnlArray = tempPnlArray
                ?.filter((entry) => {
                  // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
                  return currentDate1 - entry[0] <= 360 * 24 * 60 * 60 * 1000;
                })
                .map((entry) => JSON.parse(JSON.stringify(entry)));

              let pnlDataFromOrigin = [
                ...lastYearPnlArray,
                [currentDate1 - 360 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]);
              setGraphPnlData(pnlDataFromOrigin);

              const currentDate2 = new Date().getTime();
              let lastYearPerArray = tempPerArray
                ?.filter((entry) => {
                  // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
                  return currentDate2 - entry[0] <= 360 * 24 * 60 * 60 * 1000;
                })
                .map((entry) => JSON.parse(JSON.stringify(entry)));
              let perDataFromOrigin = [
                ...lastYearPerArray,
                [currentDate1 - 360 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]);
              setGraphPerData(perDataFromOrigin);
              setIsLoading(false);
            }
            break;
          case "month":
            if (1) {
              const now = new Date();
              const minx = new Date(now.setDate(now.getDate() - 29)).getTime();
              const maxx = new Date(now.setDate(now.getDate() + 30)).getTime();
              setmaxValueX(maxx);
              setminValueX(minx);
              chartRef?.current?.chart?.updateOptions({
                xaxis: {
                  type: "datetime",
                  min: minx,
                  max: maxx,
                },
              });

              const currentDate1 = new Date().getTime();
              let lastMonthPnlArray = tempPnlArray
                ?.filter((entry) => {
                  // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
                  return currentDate1 - entry[0] <= 29 * 24 * 60 * 60 * 1000;
                })
                .map((entry) => JSON.parse(JSON.stringify(entry)));

              let pnlDataFromOrigin = [
                ...lastMonthPnlArray,
                [currentDate1 - 29 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]);
              setGraphPnlData(pnlDataFromOrigin);

              const currentDate2 = new Date().getTime();
              let lastMonthPerArray = tempPerArray
                ?.filter((entry) => {
                  // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
                  return currentDate2 - entry[0] <= 29 * 24 * 60 * 60 * 1000;
                })
                .map((entry) => JSON.parse(JSON.stringify(entry)));

              let perDataFromOrigin = [
                ...lastMonthPerArray,
                [currentDate1 - 29 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]);
              setGraphPerData(perDataFromOrigin);
              setIsLoading(false);
            }
            break;
          case "week":
            if (1) {
              const now = new Date();
              const minx = new Date(now.setDate(now.getDate() - 6)).getTime();
              const maxx = new Date(now.setDate(now.getDate() + 6)).getTime();
              setmaxValueX(maxx);
              setminValueX(minx);
              chartRef?.current?.chart?.updateOptions({
                xaxis: {
                  type: "datetime",
                  min: minx,
                  max: maxx,
                },
              });

              const currentDate1 = new Date().getTime();
              let lastSevenDaysPnlArray = tempPnlArray
                ?.filter((entry) => {
                  // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
                  return currentDate1 - entry[0] <= 6 * 24 * 60 * 60 * 1000;
                })
                .map((entry) => JSON.parse(JSON.stringify(entry)));

              let pnlDataFromOrigin = [
                ...lastSevenDaysPnlArray,
                [currentDate1 - 6 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]);

              setGraphPnlData(pnlDataFromOrigin);

              const currentDate2 = new Date().getTime();
              let lastSevenDaysPerArray = tempPerArray
                ?.filter((entry) => {
                  // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
                  return currentDate2 - entry[0] <= 6 * 24 * 60 * 60 * 1000;
                })
                .map((entry) => JSON.parse(JSON.stringify(entry)));

              let perDataFromOrigin = [
                ...lastSevenDaysPerArray,
                [currentDate2 - 6 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]);
              setGraphPerData(perDataFromOrigin);

              setIsLoading(false);
            }
            break;
          case "all":
            if (chartRef?.current && Object.keys(tempPnlArray)?.length > 0) {
              const allPnlData = [
                ...tempPnlArray,
                [tempPnlArray[0][0] - 1 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]); // Assuming initialData contains all the data
              const minx = Math.min(...allPnlData.map((entry) => entry[0])); // Minimum timestamp in the data
              const maxx = Math.max(...allPnlData.map((entry) => entry[0])); // Maximum timestamp in the data
              setmaxValueX(maxx);
              setminValueX(minx);
              chartRef?.current?.chart?.updateOptions({
                xaxis: {
                  type: "datetime",
                  min: minx,
                  max: maxx,
                },
              });

              setGraphPnlData(allPnlData); // Use all data for the graph

              const allPerData = [
                ...tempPerArray,
                [tempPerArray[0][0] - 1 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]);
              setGraphPerData(allPerData);

              setIsLoading(false);
            }
            break;
        }

        // eslint-disable-next-line default-case
        switch (meterTimeScale) {
          case "year":
            {
              // const now = new Date();

              let currentDate1 = new Date().getTime();
              let lastYearPnlArray = tempPnlArray
                ?.filter((entry) => {
                  // Check if the difference between the current date and the entry's date is less than or equal to 365 days (in milliseconds)
                  return currentDate1 - entry[0] <= 360 * 24 * 60 * 60 * 1000;
                })
                .map((entry) => JSON.parse(JSON.stringify(entry)));

              let netPnl = lastYearPnlArray[lastYearPnlArray.length - 1][1];
              netPnl ? setNetPnl(netPnl) : setNetPnl(0);

              let currentDate2 = new Date().getTime();
              let lastYearPerArray = tempPerArray
                ?.filter((entry) => {
                  // Check if the difference between the current date and the entry's date is less than or equal to 365 days (in milliseconds)
                  return currentDate2 - entry[0] <= 360 * 24 * 60 * 60 * 1000;
                })
                .map((entry) => JSON.parse(JSON.stringify(entry)));

              let netPer = lastYearPerArray[lastYearPerArray.length - 1][1];
              netPer ? setNetPer(netPer) : setNetPer(0);
            }
            break;
          case "month":
            {
              const now = new Date();

              let currentDate1 = new Date().getTime();
              let lastMonthPnlArray = tempPnlArray
                ?.filter((entry) => {
                  // Check if the difference between the current date and the entry's date is less than or equal to 30 days (in milliseconds)
                  return currentDate1 - entry[0] <= 29 * 24 * 60 * 60 * 1000;
                })
                .map((entry) => JSON.parse(JSON.stringify(entry)));

              let netPnl = lastMonthPnlArray[lastMonthPnlArray.length - 1][1];
              netPnl ? setNetPnl(netPnl) : setNetPnl(0);

              let currentDate2 = new Date().getTime();
              let lastMonthPerArray = tempPerArray
                ?.filter((entry) => {
                  // Check if the difference between the current date and the entry's date is less than or equal to 30 days (in milliseconds)
                  return currentDate1 - entry[0] <= 29 * 24 * 60 * 60 * 1000;
                })
                .map((entry) => JSON.parse(JSON.stringify(entry)));

              let netPer = lastMonthPerArray[lastMonthPerArray.length - 1][1];
              netPer ? setNetPer(netPer) : setNetPer(0);
            }
            break;
          case "week":
            {
              const now = new Date();
              const currentDate1 = new Date().getTime();
              let lastSevenDaysPnlArray = tempPnlArray
                ?.filter((entry) => {
                  // Check if the difference tempPnlArray the current date and the entry's date is less than or equal to 7 days (in milliseconds)
                  return currentDate1 - entry[0] <= 6 * 24 * 60 * 60 * 1000;
                })
                .map((entry) => JSON.parse(JSON.stringify(entry)));
              let netPnl =
                lastSevenDaysPnlArray[lastSevenDaysPnlArray.length - 1][1];
              netPnl ? setNetPnl(netPnl) : setNetPnl(0);

              const currentDate2 = new Date().getTime();
              let lastSevenDaysPerArray = tempPerArray
                ?.filter((entry) => {
                  // Check if the difference tempPnlArray the current date and the entry's date is less than or equal to 7 days (in milliseconds)
                  return currentDate2 - entry[0] <= 6 * 24 * 60 * 60 * 1000;
                })
                .map((entry) => JSON.parse(JSON.stringify(entry)));

              let netPer =
                lastSevenDaysPerArray[lastSevenDaysPerArray.length - 1][1];
              netPer ? setNetPer(netPer) : setNetPer(0);
            }
            break;
          case "daily":
            {
              const now = new Date();

              let currentDate1 = new Date().getTime();
              let lastDayPnlArray = tempPnlArray
                ?.filter((entry) => {
                  // Check if the difference between the current date and the entry's date is less than or equal to 30 days (in milliseconds)
                  return currentDate1 - entry[0] <= 1 * 24 * 60 * 60 * 1000;
                })
                .map((entry) => JSON.parse(JSON.stringify(entry)));
              let netPnl = lastDayPnlArray[lastDayPnlArray.length - 1]?.[1];
              netPnl ? setNetPnl(netPnl) : setNetPnl(0);

              let currentDate2 = new Date().getTime();
              let lastDayPerArray = tempPerArray
                ?.filter((entry) => {
                  // Check if the difference between the current date and the entry's date is less than or equal to 30 days (in milliseconds)
                  return currentDate2 - entry[0] <= 1 * 24 * 60 * 60 * 1000;
                })
                .map((entry) => JSON.parse(JSON.stringify(entry)));

              let netPer = lastDayPerArray[lastDayPerArray.length - 1]?.[1];
              netPer ? setNetPer(netPer) : setNetPer(0);
            }
            break;
          case "all":
            {
              let netPnl = 0;
              tempPnlArray?.map((item) => {
                netPnl += item[1];
              });
              setNetPnl(netPnl?.toFixed(2));

              let netPer = 0;
              tempPerArray?.map((item) => {
                netPer += item[1];
              });
              setNetPer(netPer?.toFixed(2));
            }
            break;
        }
      } else {
        setmaxValueY(200);
        setminValueY(-200);
        setInitialPnLData([]);

        setmaxPerY(100);
        setminPerY(-100);
        setInitialPerData([]);
        // eslint-disable-next-line default-case
        switch (graphTimeScale) {
          case "year":
            if (1) {
              const now = new Date();
              const minx = new Date(
                now.getFullYear() - 1,
                now.getMonth(),
                now.getDate() + 2
              ).getTime(); // Start from one year ago from today
              const maxx = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + 2
              ).getTime(); // End at one year ahead from today
              setmaxValueX(maxx);
              setminValueX(minx);
              chartRef?.current?.chart?.updateOptions({
                xaxis: {
                  type: "datetime",
                  min: minx,
                  max: maxx,
                },
              });

              const currentDate1 = new Date().getTime();
              let lastYearPnlArray = initialPnlData?.filter((entry) => {
                // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
                return currentDate1 - entry[0] <= 360 * 24 * 60 * 60 * 1000;
              });
              let pnlDataFromOrigin = [
                ...lastYearPnlArray,
                [currentDate1 - 360 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]);
              setGraphPnlData(pnlDataFromOrigin);

              const currentDate2 = new Date().getTime();
              let lastYearPerArray = initialPerData?.filter((entry) => {
                // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
                return currentDate2 - entry[0] <= 360 * 24 * 60 * 60 * 1000;
              });
              let perDataFromOrigin = [
                ...lastYearPerArray,
                [currentDate1 - 360 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]);
              setGraphPerData(perDataFromOrigin);
              setIsLoading(false);
            }
            break;
          case "month":
            if (1) {
              const now = new Date();
              const minx = new Date(now.setDate(now.getDate() - 29)).getTime();
              const maxx = new Date(now.setDate(now.getDate() + 30)).getTime();
              setmaxValueX(maxx);
              setminValueX(minx);
              chartRef?.current?.chart?.updateOptions({
                xaxis: {
                  type: "datetime",
                  min: minx,
                  max: maxx,
                },
              });

              const currentDate1 = new Date().getTime();
              let lastMonthPnlArray = initialPnlData?.filter((entry) => {
                // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
                return currentDate1 - entry[0] <= 29 * 24 * 60 * 60 * 1000;
              });
              let pnlDataFromOrigin = [
                ...lastMonthPnlArray,
                [currentDate1 - 29 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]);
              setGraphPnlData(pnlDataFromOrigin);

              const currentDate2 = new Date().getTime();
              let lastMonthPerArray = initialPerData?.filter((entry) => {
                // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
                return currentDate2 - entry[0] <= 29 * 24 * 60 * 60 * 1000;
              });
              let perDataFromOrigin = [
                ...lastMonthPerArray,
                [currentDate1 - 29 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]);
              setGraphPerData(perDataFromOrigin);
              setIsLoading(false);
            }
            break;
          case "week":
            if (1) {
              const now = new Date();
              const minx = new Date(now.setDate(now.getDate() - 6)).getTime();
              const maxx = new Date(now.setDate(now.getDate() + 6)).getTime();
              setmaxValueX(maxx);
              setminValueX(minx);
              chartRef?.current?.chart?.updateOptions({
                xaxis: {
                  type: "datetime",
                  min: minx,
                  max: maxx,
                },
              });

              const currentDate1 = new Date().getTime();
              let lastSevenDaysPnlArray = initialPnlData?.filter((entry) => {
                // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
                return currentDate1 - entry[0] <= 6 * 24 * 60 * 60 * 1000;
              });
              let pnlDataFromOrigin = [
                ...lastSevenDaysPnlArray,
                [currentDate1 - 6 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]);
              setGraphPnlData(pnlDataFromOrigin);

              const currentDate2 = new Date().getTime();
              let lastSevenDaysPerArray = initialPerData?.filter((entry) => {
                // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
                return currentDate2 - entry[0] <= 6 * 24 * 60 * 60 * 1000;
              });
              let perDataFromOrigin = [
                ...lastSevenDaysPerArray,
                [currentDate2 - 6 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]);
              setGraphPerData(perDataFromOrigin);

              setIsLoading(false);
            }
            break;
          case "all":
            if (chartRef?.current && Object.keys(initialPnlData)?.length > 0) {
              const allPnlData = [
                ...initialPnlData,
                [initialPnlData[0][0] - 1 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]); // Assuming initialData contains all the data
              const minx = Math.min(...allPnlData.map((entry) => entry[0])); // Minimum timestamp in the data
              const maxx = Math.max(...allPnlData.map((entry) => entry[0])); // Maximum timestamp in the data
              setmaxValueX(maxx);
              setminValueX(minx);
              chartRef?.current?.chart?.updateOptions({
                xaxis: {
                  type: "datetime",
                  min: minx,
                  max: maxx,
                },
              });

              setGraphPnlData(allPnlData); // Use all data for the graph

              const allPerData = [
                ...initialPerData,
                [initialPerData[0][0] - 1 * 24 * 60 * 60 * 1000, 0],
              ].sort((a, b) => a[0] - b[0]);
              setGraphPerData(allPerData);

              setIsLoading(false);
            }
            break;
        }
      }
      // setIsLoading(false);
    }
  }, [btoApiData]);

  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (graphTimeScale) {
      case "year":
        if (chartRef?.current) {
          const now = new Date();
          const minx = new Date(
            now.getFullYear() - 1,
            now.getMonth(),
            now.getDate() + 2
          ).getTime(); // Start from one year ago from today
          const maxx = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 2
          ).getTime(); // End at one year ahead from today
          setmaxValueX(maxx);
          setminValueX(minx);
          chartRef.current.chart.updateOptions({
            xaxis: {
              type: "datetime",
              min: minx,
              max: maxx,
            },
          });

          const currentDate1 = new Date().getTime();
          let lastYearPnlArray = initialPnlData
            ?.filter((entry) => {
              // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
              return currentDate1 - entry[0] <= 360 * 24 * 60 * 60 * 1000;
            })
            .map((entry) => JSON.parse(JSON.stringify(entry)));
          let pnlDataFromOrigin = [
            ...lastYearPnlArray,
            [currentDate1 - 360 * 24 * 60 * 60 * 1000, 0],
          ].sort((a, b) => a[0] - b[0]);
          setGraphPnlData(pnlDataFromOrigin);

          const currentDate2 = new Date().getTime();
          let lastYearPerArray = initialPerData
            ?.filter((entry) => {
              // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
              return currentDate2 - entry[0] <= 360 * 24 * 60 * 60 * 1000;
            })
            .map((entry) => JSON.parse(JSON.stringify(entry)));
          let perDataFromOrigin = [
            ...lastYearPerArray,
            [currentDate1 - 360 * 24 * 60 * 60 * 1000, 0],
          ].sort((a, b) => a[0] - b[0]);
          setGraphPerData(perDataFromOrigin);
        }
        break;
      case "month":
        if (chartRef?.current) {
          const now = new Date();
          const minx = new Date(now.setDate(now.getDate() - 29)).getTime();
          const maxx = new Date(now.setDate(now.getDate() + 30)).getTime();
          setmaxValueX(maxx);
          setminValueX(minx);
          chartRef.current.chart.updateOptions({
            xaxis: {
              type: "datetime",
              min: minx,
              max: maxx,
            },
          });

          const currentDate1 = new Date().getTime();
          let lastMonthPnlArray = initialPnlData
            ?.filter((entry) => {
              // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
              return currentDate1 - entry[0] <= 29 * 24 * 60 * 60 * 1000;
            })
            .map((entry) => JSON.parse(JSON.stringify(entry)));
          let pnlDataFromOrigin = [
            ...lastMonthPnlArray,
            [currentDate1 - 29 * 24 * 60 * 60 * 1000, 0],
          ].sort((a, b) => a[0] - b[0]);
          setGraphPnlData(pnlDataFromOrigin);

          const currentDate2 = new Date().getTime();
          let lastMonthPerArray = initialPerData
            ?.filter((entry) => {
              // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
              return currentDate2 - entry[0] <= 29 * 24 * 60 * 60 * 1000;
            })
            .map((entry) => JSON.parse(JSON.stringify(entry)));
          let perDataFromOrigin = [
            ...lastMonthPerArray,
            [currentDate1 - 29 * 24 * 60 * 60 * 1000, 0],
          ].sort((a, b) => a[0] - b[0]);
          setGraphPerData(perDataFromOrigin);
        }
        break;
      case "week":
        if (chartRef?.current) {
          const now = new Date();
          const minx = new Date(now.setDate(now.getDate() - 6)).getTime();
          const maxx = new Date(now.setDate(now.getDate() + 6)).getTime();
          setmaxValueX(maxx);
          setminValueX(minx);
          chartRef.current.chart.updateOptions({
            xaxis: {
              type: "datetime",
              min: minx,
              max: maxx,
            },
          });

          const currentDate1 = new Date().getTime();
          let lastSevenDaysPnlArray = initialPnlData
            ?.filter((entry) => {
              // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
              return currentDate1 - entry[0] <= 6 * 24 * 60 * 60 * 1000;
            })
            .map((entry) => JSON.parse(JSON.stringify(entry)));
          let pnlDataFromOrigin = [
            ...lastSevenDaysPnlArray,
            [currentDate1 - 6 * 24 * 60 * 60 * 1000, 0],
          ].sort((a, b) => a[0] - b[0]);
          setGraphPnlData(pnlDataFromOrigin);

          const currentDate2 = new Date().getTime();
          let lastSevenDaysPerArray = initialPerData
            ?.filter((entry) => {
              // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
              return currentDate2 - entry[0] <= 6 * 24 * 60 * 60 * 1000;
            })
            .map((entry) => JSON.parse(JSON.stringify(entry)));
          let perDataFromOrigin = [
            ...lastSevenDaysPerArray,
            [currentDate2 - 6 * 24 * 60 * 60 * 1000, 0],
          ].sort((a, b) => a[0] - b[0]);
          setGraphPerData(perDataFromOrigin);
        }
        break;
      case "all":
        if (chartRef?.current && Object.keys(initialPnlData)?.length > 0) {
          const allPnlData = [
            ...initialPnlData,
            [initialPnlData[0][0] - 1 * 24 * 60 * 60 * 1000, 0],
          ].sort((a, b) => a[0] - b[0]); // Assuming initialData contains all the data
          const minx = Math.min(...allPnlData.map((entry) => entry[0])); // Minimum timestamp in the data
          const maxx = Math.max(...allPnlData.map((entry) => entry[0])); // Maximum timestamp in the data
          setmaxValueX(maxx);
          setminValueX(minx);
          chartRef?.current?.chart?.updateOptions({
            xaxis: {
              type: "datetime",
              min: minx,
              max: maxx,
            },
          });

          setGraphPnlData(allPnlData); // Use all data for the graph

          const allPerData = [
            ...initialPerData,
            [initialPerData[0][0] - 1 * 24 * 60 * 60 * 1000, 0],
          ].sort((a, b) => a[0] - b[0]);
          setGraphPerData(allPerData);
        }
        break;
    }
  }, [graphTimeScale]);

  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (meterTimeScale) {
      case "year":
        {
          const now = new Date();

          let currentDate1 = new Date().getTime();
          let lastYearPnlArray = initialPnlData?.filter((entry) => {
            // Check if the difference between the current date and the entry's date is less than or equal to 365 days (in milliseconds)
            return currentDate1 - entry[0] <= 360 * 24 * 60 * 60 * 1000;
          });
          let netPnl = lastYearPnlArray[lastYearPnlArray.length - 1][1];
          netPnl ? setNetPnl(netPnl) : setNetPnl(0);

          let currentDate2 = new Date().getTime();
          let lastYearPerArray = initialPerData
            ?.filter((entry) => {
              // Check if the difference between the current date and the entry's date is less than or equal to 365 days (in milliseconds)
              return currentDate2 - entry[0] <= 360 * 24 * 60 * 60 * 1000;
            })
            .map((entry) => JSON.parse(JSON.stringify(entry)));
          let netPer = lastYearPerArray[lastYearPerArray.length - 1][1];
          netPer ? setNetPer(netPer) : setNetPer(0);
        }
        break;
      case "month":
        {
          const now = new Date();

          let currentDate1 = new Date().getTime();
          let lastMonthPnlArray = initialPnlData
            ?.filter((entry) => {
              // Check if the difference between the current date and the entry's date is less than or equal to 30 days (in milliseconds)
              return currentDate1 - entry[0] <= 29 * 24 * 60 * 60 * 1000;
            })
            .map((entry) => JSON.parse(JSON.stringify(entry)));

          let netPnl = lastMonthPnlArray[lastMonthPnlArray.length - 1][1];
          netPnl ? setNetPnl(netPnl) : setNetPnl(0);

          let currentDate2 = new Date().getTime();
          let lastMonthPerArray = initialPerData
            ?.filter((entry) => {
              // Check if the difference between the current date and the entry's date is less than or equal to 30 days (in milliseconds)
              return currentDate1 - entry[0] <= 29 * 24 * 60 * 60 * 1000;
            })
            .map((entry) => JSON.parse(JSON.stringify(entry)));
          let netPer = lastMonthPerArray[lastMonthPerArray.length - 1][1];
          netPer ? setNetPer(netPer) : setNetPer(0);
        }
        break;
      case "week":
        if (1) {
          const now = new Date();

          const currentDate1 = new Date().getTime();
          let lastSevenDaysPnlArray = initialPnlData
            ?.filter((entry) => {
              // Check if the difference tempPnlArray the current date and the entry's date is less than or equal to 7 days (in milliseconds)
              return currentDate1 - entry[0] <= 6 * 24 * 60 * 60 * 1000;
            })
            .map((entry) => JSON.parse(JSON.stringify(entry)));
          let netPnl =
            lastSevenDaysPnlArray[lastSevenDaysPnlArray.length - 1][1];
          netPnl ? setNetPnl(netPnl) : setNetPnl(0);

          const currentDate2 = new Date().getTime();
          let lastSevenDaysPerArray = initialPerData
            ?.filter((entry) => {
              // Check if the difference tempPnlArray the current date and the entry's date is less than or equal to 7 days (in milliseconds)
              return currentDate2 - entry[0] <= 6 * 24 * 60 * 60 * 1000;
            })
            .map((entry) => JSON.parse(JSON.stringify(entry)));
          let netPer =
            lastSevenDaysPerArray[lastSevenDaysPerArray.length - 1][1];
          netPer ? setNetPer(netPer) : setNetPer(0);
        }
        break;
      case "daily":
        {
          const now = new Date();

          let currentDate1 = new Date().getTime();
          let lastDayPnlArray = initialPnlData
            ?.filter((entry) => {
              // Check if the difference between the current date and the entry's date is less than or equal to 30 days (in milliseconds)
              return currentDate1 - entry[0] <= 1 * 24 * 60 * 60 * 1000;
            })
            .map((entry) => JSON.parse(JSON.stringify(entry)));
          let pnlDataFromOrigin = [
            ...lastDayPnlArray,
            [currentDate1 - 1 * 24 * 60 * 60 * 1000, 0],
          ].sort((a, b) => a[0] - b[0]);

          let netPnl = 0;
          pnlDataFromOrigin?.map((item) => {
            netPnl += item[1];
          });
          setNetPnl(netPnl?.toFixed(2));

          let currentDate2 = new Date().getTime();
          let lastDayPerArray = initialPerData
            ?.filter((entry) => {
              // Check if the difference between the current date and the entry's date is less than or equal to 30 days (in milliseconds)
              return currentDate2 - entry[0] <= 1 * 24 * 60 * 60 * 1000;
            })
            .map((entry) => JSON.parse(JSON.stringify(entry)));
          let perDataFromOrigin = [
            ...lastDayPerArray,
            [currentDate2 - 1 * 24 * 60 * 60 * 1000, 0],
          ].sort((a, b) => a[0] - b[0]);

          let netPer = 0;
          perDataFromOrigin?.map((item) => {
            netPer += item[1];
          });
          setNetPer(netPer?.toFixed(2));
          // setGraphData(dataFromOrigin);
        }
        break;
      case "all":
        {
          let netPnl = 0;
          initialPnlData?.map((item) => {
            netPnl += item[1];
          });
          setNetPnl(netPnl?.toFixed(2));

          let netPer = 0;
          initialPerData?.map((item) => {
            netPer += item[1];
          });
          setNetPer(netPer?.toFixed(2));
        }
        break;
    }
  }, [meterTimeScale]);

  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (tableTimeScale) {
      case "year":
        if (btoStoreData) {
          let currentDate = new Date().getTime();
          let lastYearArray = btoStoreData?.filter((entry) => {
            // Check if the difference between the current date and the entry's date is less than or equal to 365 days (in milliseconds)
            return currentDate - entry[0] <= 360 * 24 * 60 * 60 * 1000;
          });
          lastYearArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
          setBtoStoreFilteredData(lastYearArray);
        }
        break;
      case "month":
        if (btoStoreData) {
          let currentDate = new Date().getTime();
          let lastMonthArray = btoStoreData?.filter((entry) => {
            // Check if the difference between the current date and the entry's date is less than or equal to 30 days (in milliseconds)
            return currentDate - entry[0] <= 29 * 24 * 60 * 60 * 1000;
          });
          lastMonthArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
          setBtoStoreFilteredData(lastMonthArray);
        }
        break;
      case "week":
        if (btoStoreData) {
          const currentDate = new Date().getTime();
          let lastSevenDaysArray = btoStoreData?.filter((entry) => {
            // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
            return currentDate - entry[0] <= 6 * 24 * 60 * 60 * 1000;
          });
          lastSevenDaysArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
          setBtoStoreFilteredData(lastSevenDaysArray);
        }
        break;
      case "all":
        if (btoStoreData) {
          let completeArray = btoStoreData.sort(
            (a, b) => new Date(b[0]) - new Date(a[0])
          );
          setBtoStoreFilteredData(completeArray); // Use all data for the graph
        }
        break;
    }
  }, [tableTimeScale]);

  useEffect(() => {
    if (isOpen) {
      // Prevent scrolling of the body when the navbar is open
      document.body.style.overflow = "hidden";
    } else {
      // Restore scrolling when the navbar is closed
      document.body.style.overflow = "auto";
    }

    if (!gentradeToken) {
      if(subDomainUrl && gentradeToken){
        localStorage.clear();
        window.location.href = `${subDomainUrl}/signIn`
      }
      else{
        localStorage.clear();
        navigate("/signIn");
      }
    }
  }, [isOpen]);

  
  const handlePublishDailyStats = async () => {
    if (role !== "admin") {
      return;
    }
    setPublishLoading(true);
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE_URL}/user/publishDailyStats`,
      headers: {
        Authorization: "Bearer " + gentradeToken,
      },
      data: {
        serverId: discordServerId,
        channelId: discordChannelId,
        userId
      },
    })
      .then((response) => {
        toast.success("Daily stats published successfully!");
        setPublishLoading(false);
        setPublishDailyStats(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
        setPublishDailyStats(false);
      });
  };
  
  const handlePublishWeeklyStats = async () => {
    if (role !== "admin") {
      return;
    }
    setPublishLoading(true);
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE_URL}/user/publishWeeklyStats`,
      headers: {
        Authorization: "Bearer " + gentradeToken,
      },
      data: {
        serverId: discordServerId,
        channelId: discordChannelId,
        userId
      },
    })
      .then((response) => {
        toast.success("Weekly stats published successfully!");
        setPublishLoading(false);
        setPublishWeeklyStats(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
        setPublishWeeklyStats(false);
      });
  };

  //For fetching discord channels and server
  const [discordServerId, setDiscordServerId] = useState("");
  const [discordChannels, setDiscordChannels] = useState([]);
  const [discordChannelId, setDiscordChannelId] = useState("");
  const [loading, setLoading] = useState(false);
  const { selectedDiscordChannel, setSelectedDiscordChannel } = userStore(
    (state) => state
  );
  const { currentDiscordServer } = userData || {};

  const setDataInLocalStorage = (name, key, value) => {
    const data = JSON.parse(localStorage.getItem(name) || `{}`);
    data[key] = value;
    localStorage.setItem(name, JSON.stringify(data));
  };

  useEffect(() => {
    if (discordServers?.length) {
      try {
        // let discordGuildId = discordServers[0].id;
        let discordGuildId = currentDiscordServer.id;
        console.log(discordGuildId, discordServers);
        setDiscordServerId(discordGuildId);
        setDataInLocalStorage(
          "btoMetaData",
          "discordServerId",
          discordGuildId.toString()
        );
        (async () => {
          const channelsResponse = await getChannelsOfServer(
            discordGuildId,
            gentradeToken
          );
          console.log(channelsResponse);
          // setDiscordChannels([channelsResponse.data?.[0]]);
          setDiscordChannels(channelsResponse.data);
          setDiscordChannelId(channelsResponse.data?.[0]?.id);
          setDataInLocalStorage(
            "btoMetaData",
            "discordChannelId",
            channelsResponse.data?.[0]?.id
          );
          setDataInLocalStorage(
            "cachedDiscordChannels",
            discordGuildId,
            channelsResponse.data
          );
        })();
      } catch (err) {
        console.log(err);
        toast(
          err.response?.data || "Something went wrong, please try again later",
          {
            type: "error",
          }
        );
      }
    }
  }, [discordServers]);

  return (
    <div
      className="min-h-screen flex flex-col lg:flex-row bg bg-cover bg-center"
      // style={{backgroundImage: `url(${bgLeft})`}}
    >
      <Sidebar
        bigNavToggle={bigNavToggle}
        setbigNavToggle={setbigNavToggle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        route={"stats"}
      />

      {/* Body for larger screens  */}
      <div
        className={`hidden lg:block ${
          bigNavToggle ? "lg:w-[80%]" : "lg:w-[96%]"
        } lg:w-[80%] bg-cover bg-no-repeat bg-left px-12 py-8 text-[15px]`}
        style={{ backgroundImage: `url(${bgLeft})` }}
      >
        {(publishDailyStats || publishWeeklyStats) && (
          <div
            onClick={() => {setPublishDailyStats(false)
              setPublishWeeklyStats(false)
            }}
            className={`fixed h-screen w-full bg-black bg-opacity-60 z-30 top-0 left-0
              ${publishLoading ? "pointer-events-none" : ""}
            `}
          />
        )}
      {publishDailyStats && (
        <div className="w-[40%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-[#191919] rounded-lg py-6 px-6">
          <div className="text-2xl font-semibold text-white">
            Publish Daily Stats
          </div>
          <div className="w-full h-[1px] mt-6" />
          <div className="flex mt-8 lg:mt-0 text-white flex-col md:flex-ro w gap-4">
            <div className="flex items-center gap-2">
              <label className="w-36">Discord Servers:</label>
              <select
                className="bg-[#424242] px-2 py-1 rounded-md"
                value={currentDiscordServer?.id}
                onChange={(e) => {
                  setDiscordServerId(e.target.value);
                  setDataInLocalStorage(
                    "btoMetaData",
                    "discordServerId",
                    e.target.value
                  );
                }}
              >
                <option value={currentDiscordServer?.id}>
                  {currentDiscordServer?.name}
                </option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              <label className="w-36">Discord Channels:</label>
              {loading ? (
                <div className="flex gap-2 items-center">
                  Fetching
                  <AiOutlineLoading3Quarters className="animate-spin" />{" "}
                </div>
              ) : (
                <select
                  className="bg-[#424242] px-2 py-[2px] rounded-md"
                  value={discordChannelId}
                  onChange={(e) => {
                    setDiscordChannelId(e.target.value);
                    setSelectedDiscordChannel(e.target.value);
                    setDataInLocalStorage(
                      "btoMetaData",
                      "discordChannelId",
                      e.target.value
                    );
                  }}
                >
                  {discordChannels.map((d, i) => (
                    <option value={d.id} key={i}>
                      {d.name}
                    </option>
                  ))}{" "}
                </select>
              )}
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              disabled={publishLoading}
              onClick={() => setPublishDailyStats(false)}
              className="disabled:bg-[#737B7B]  bg-red-700 text-white py-1 w-28 rounded-md "
            >
              Cancel
            </button>
            <button
              disabled={publishLoading}
              onClick={handlePublishDailyStats}
              className="border border-tertiary [#737B7B] py-1 w-28 text-black bg-tertiary [#5F6868] rounded-md font-semibold"
            >
              Publish
            </button>
          </div>
        </div>
      )}
      {publishWeeklyStats && (
        <div className="w-[40%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-[#191919] rounded-lg py-6 px-6">
          <div className="text-2xl font-semibold  text-white">
            Publish Weekly Stats
          </div>
          <div className="w-full h-[1px] mt-6" />
          <div className="flex mt-8 lg:mt-0 text-white flex-col md:flex-ro w gap-4">
            <div className="flex items-center gap-2">
              <label className="w-36">Discord Servers:</label>
              <select
                className="bg-[#424242] px-2 py-1 rounded-md"
                value={currentDiscordServer?.id}
                onChange={(e) => {
                  setDiscordServerId(e.target.value);
                  setDataInLocalStorage(
                    "btoMetaData",
                    "discordServerId",
                    e.target.value
                  );
                }}
              >
                <option value={currentDiscordServer?.id}>
                  {currentDiscordServer?.name}
                </option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              <label className="w-36">Discord Channels:</label>
              {loading ? (
                <div className="flex gap-2 items-center">
                  Fetching
                  <AiOutlineLoading3Quarters className="animate-spin" />{" "}
                </div>
              ) : (
                <select
                  className="bg-[#424242] px-2 py-[2px] rounded-md"
                  value={discordChannelId}
                  onChange={(e) => {
                    setDiscordChannelId(e.target.value);
                    setSelectedDiscordChannel(e.target.value);
                    setDataInLocalStorage(
                      "btoMetaData",
                      "discordChannelId",
                      e.target.value
                    );
                  }}
                >
                  {discordChannels.map((d, i) => (
                    <option value={d.id} key={i}>
                      {d.name}
                    </option>
                  ))}{" "}
                </select>
              )}
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              disabled={publishLoading}
              onClick={() => setPublishWeeklyStats(false)}
              className="disabled:bg-[#737B7B]  bg-red-700 text-white py-1 w-28 rounded-md "
            >
              Cancel
            </button>
            <button
              disabled={publishLoading}
              onClick={handlePublishWeeklyStats}
              className="border border-tertiary [#737B7B] py-1 w-28 text-black bg-tertiary [#5F6868] rounded-md font-semibold"
            >
              Publish
            </button>
          </div>
        </div>
      )}
        <div className="flex justify-end gap-4">
          <button
            onClick={() => setPublishDailyStats(true)}
            className="flex items-center gap-2 text-white [#646464]  px-2 py-1 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
          >
            <img src={daily} className="size-4" />
            Publish Daily Stats
          </button>
          <button
            onClick={() => setPublishWeeklyStats(true)}
            className="flex items-center gap-2 text-white [#646464] px-2 py-1 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
          >
            <img src={monthly} className="size-4" />
            Publish Weekly Stats
          </button>
          
          <div className="flex gap-2 text-tertiary [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1">
            Current balance:
            <div className="text-white">{currentBalance}</div>
          </div>
          <div className="flex">
          {role == "admin" ? (
            <Link
              to={"/admin/userInfo"}
              className="text-tertiary px-2 py-1 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
            >
              Admin Portal
            </Link>
          ) : (
            ""
          )}
          <img
            src={avatarUrl || profile}
            alt="profile"
            className="ml-6 border border-white rounded-full size-[34px]"
          />
          </div>
        </div>

        {/* Main Body  */}
        {isLoading ? (
          <div className="text-4xl h-full text-white pb-8 flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <div>
            <div className="flex gap-4 mt-4">
              <div className="bg-[#202020] rounded-3xl px-4 py-4 w-1/2">
                {/* Graph  */}
                <div className="flex justify-between">
                  <div className="flex gap-4 items-center text-[#A1A1A1] pl-4 text-lg">
                    <div>PnL Chart</div>
                    <div className="flex gap- text-base items-center">
                      <div
                        onClick={() => setPnlChartType("percentage")}
                        className={` rounded-l-md border w-16 text-center border-[#A1A1A1] cursor-pointer px-3 ${
                          pnlChartType === "percentage"
                            ? "bg-tertiary text-black font-bold border-tertiary drop-shadow-xl"
                            : "bg-[#]"
                        }`}
                      >
                        PnL%
                      </div>
                      <div
                        onClick={() => setPnlChartType("pnl")}
                        className={` rounded-r-md border w-16 text-center border-[#A1A1A1] cursor-pointer px-3 ${
                          pnlChartType === "pnl"
                            ? "bg-tertiary text-black font-bold border-tertiary drop-shadow-xl"
                            : "bg-[#]"
                        }`}
                      >
                        PnL
                      </div>
                    </div>
                  </div>
                  <select
                    value={graphTimeScale}
                    onChange={(e) => setGraphTimeScale(e.target.value)}
                    className="form-select bg-transparent border pr-4 pl-1 py-2 border-[#6E6E6E]  rounded-lg text-tertiary"
                  >
                    <option className="bg-[#202020]" value="week">
                      Last 7 Days
                    </option>
                    <option className="bg-[#202020]" value="month">
                      Last Month
                    </option>
                    <option className="bg-[#202020]" value="year">
                      Last Year
                    </option>
                    <option className="bg-[#202020]" value="all">
                      All
                    </option>
                  </select>
                </div>
                {pnlChartType === "percentage" ? (
                  <Chart
                    options={options2}
                    ref={chartRef}
                    series={options2.series}
                    type="line"
                    width="100%"
                  />
                ) : (
                  <Chart
                    options={options}
                    ref={chartRef}
                    series={options.series}
                    type="line"
                    width="100%"
                  />
                )}
                {/* <Chart
                  options={options}
                  ref={chartRef}
                  series={options.series}
                  type="line"
                  width="100%"
                /> */}
              </div>
              <div className="relative bg-[#202020] rounded-3xl px-4 py-4 w-1/2">
                <div className="flex justify-between items-center gap-4 mt-">
                  <div className="flex gap-4 items-center text-[#A1A1A1] pl-4 text-lg">
                    <div>Daily PnL</div>
                    <div className="flex gap- text-base items-center">
                      <div
                        onClick={() => setPnlMeterType("percentage")}
                        className={` rounded-l-md border w-16 text-center border-[#A1A1A1] cursor-pointer px-3 ${
                          pnlMeterType === "percentage"
                            ? "bg-tertiary text-black font-bold border-tertiary drop-shadow-xl"
                            : "bg-[#]"
                        }`}
                      >
                        PnL%
                      </div>
                      <div
                        onClick={() => setPnlMeterType("pnl")}
                        className={` rounded-r-md border w-16 text-center border-[#A1A1A1] cursor-pointer px-3 ${
                          pnlMeterType === "pnl"
                            ? "bg-tertiary text-black font-bold border-tertiary drop-shadow-xl"
                            : "bg-[#]"
                        }`}
                      >
                        PnL
                      </div>
                    </div>
                  </div>
                  {/* <select
                    value={meterTimeScale}
                    onChange={(e) => setMeterTimeScale(e.target.value)}
                    className="form-select bg-transparent border pr-4 pl-1 py-2 border-[#6E6E6E]  rounded-lg text-tertiary"
                  >
                    <option className="bg-[#202020]" value="daily">
                      Daily
                    </option>
                    <option className="bg-[#202020]" value="week">
                      Last 7 Days
                    </option>
                    <option className="bg-[#202020]" value="month">
                      Last Month
                    </option>
                    <option className="bg-[#202020]" value="year">
                      Last Year
                    </option>
                    <option className="bg-[#202020]" value="all">
                      All
                    </option>
                  </select> */}
                </div>
                {/* PnL meter */}
                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 ">
                  {pnlMeterType === "percentage" ? (
                    <ReactSpeedometer
                      width={300}
                      height={200}
                      needleHeightRatio={0.4}
                      maxSegmentLabels={0}
                      segments={6}
                      value={
                        netPer !== undefined
                          ? netPer >= 0
                            ? netPer <= 1000
                              ? netPer
                              : 1000
                            : netPer >= -1000
                            ? netPer
                            : -1000
                          : initialPerData?.[initialPerData.length - 1]?.[1]
                      }
                      currentValueText={`
                      ${
                        netPer !== undefined
                          ? netPer + "%"
                          : initialPerData?.[initialPerData.length - 1]?.[1] +
                            "%"
                      }`}
                      maxValue={1000}
                      minValue={-1000}
                      forceRender={true}
                      startColor={"#FF4A1A"}
                      endColor={"#31AB36"}
                      paddingHorizontal={17}
                      paddingVertical={27}
                      // segmentColors={['#E83838', '#5DBC55']}
                      needleColor="lightgreen"
                      textColor="white"
                      valueTextFontSize={"30px"}
                    />
                  ) : (
                    <ReactSpeedometer
                      width={300}
                      height={200}
                      needleHeightRatio={0.4}
                      maxSegmentLabels={0}
                      segments={6}
                      value={
                        netPnl !== undefined
                          ? netPnl >= 0
                            ? netPnl <= 50000
                              ? netPnl
                              : 50000
                            : netPnl >= -50000
                            ? netPnl
                            : -50000
                          : initialPnlData?.[initialPnlData.length - 1]?.[1]
                      }
                      currentValueText={`
                    ${
                      initialPnlData?.[initialPnlData.length - 1]?.[1] > 0
                        ? "+"
                        : ""
                    }${
                        netPnl !== undefined
                          ? "$" + netPnl
                          : "$" +
                            initialPnlData?.[initialPnlData.length - 1]?.[1]
                      }`}
                      maxValue={50000}
                      minValue={-50000}
                      forceRender={true}
                      startColor={"#FF4A1A"}
                      endColor={"#31AB36"}
                      paddingHorizontal={17}
                      paddingVertical={27}
                      // segmentColors={['#E83838', '#5DBC55']}
                      needleColor="lightgreen"
                      textColor="white"
                      valueTextFontSize={"30px"}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="mt-4 bg-[#202020] px-8 py-4 rounded-3xl">
              <div className="flex justify-between items-center">
                <div className="text-lg text-[#A1A1A1]">My Trades</div>
                <select
                  value={tableTimeScale}
                  onChange={(e) => setTableTimeScale(e.target.value)}
                  className="form-select bg-transparent border pr-4 pl-1 py-2 border-[#6E6E6E]  rounded-lg text-tertiary"
                >
                  <option className="bg-[#202020]" value="week">
                    Last 7 Days
                  </option>
                  <option className="bg-[#202020]" value="month">
                    Last Month
                  </option>
                  <option className="bg-[#202020]" value="year">
                    Last Year
                  </option>
                  <option className="bg-[#202020]" value="all">
                    All
                  </option>
                </select>
              </div>
              <div className="mt-6">
                {btoStoreFilteredData?.map((item, index) => (
                  <div>
                    <Accordion index={index} item={item} />
                  </div>
                ))}
                {btoStoreFilteredData?.length === 0 && (
                  <div className="flex w-full justify-center items-center h-48 text-[#7b7B7B] font-semibold text-xl">
                    No Bto's available
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Body for smaller screens  */}
      {isLoading ? (
        <div className="lg:hidden text-4xl h-[80vh] text-white pb-8 flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div
          className="lg:hidden min-h-screen w-full bg-cover bg-center bg-no-repeat px-4 z-0"
          style={{ backgroundImage: `url(${bgPhoneTop})` }}
          onClick={() => setIsOpen(false)}
        >
          <div className="flex ml-auto w-fit gap-2 text-tertiary [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1 mt-6">
            Current balance:
            <div className="text-white">{currentBalance}</div>
          </div>
          <button
            onClick={() => setPublishDailyStats(true)}
            className="flex  items-center ml-auto w-fit gap-2 text-white [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1 mt-4"
          >
            <img src={daily} className="size-4" />
            Publish Daily Stats
          </button>
          <button
            onClick={() => setPublishWeeklyStats(true)}
            className="flex  items-center ml-auto w-fit gap-2 text-white [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1 mt-4"
          >
            <img src={monthly} className="size-4" />
            Publish Weekly Stats
          </button>
          {/* <div className="flex flex-co justify-between gap-4 mt-4">
            <div className="w-[100px] center text-white border-[#A1A1A1] rounded-md px-2 py-[2px]">
              <span className="text-[#A1A1A1] font-semibold">Invested</span> $
              {investedAmount ? formatter.format(investedAmount) : "-"}
            </div>
            <div
              className={`w-[100px] center text-tertiary border-[#A1A1A1] rounded-md px-2 py-[2px]
                ${
                  currentBalance < investedAmount
                    ? " text-red-600"
                    : currentBalance > investedAmount
                    ? "text-tertiary"
                    : "text-white"
                }
              `}
            >
              <span className="text-[#A1A1A1] font-semibold">Current</span> $
              {currentBalance ? formatter.format(currentBalance) : "-"}
            </div>
          </div> */}
          <div className="flex gap-4 mt-4">
            <div className="bg-[#202020] rounded-3xl px-4 py-4 w-full">
              {/* Graph  */}
              <div className="flex justify-between items-start mb-2">
                {/* <div className="text-[#A1A1A1] pl-4 text-lg">PnL Chart</div> */}
                <div className="flex flex-col justify-start gap-4 text-[#A1A1A1] pl-4 text-lg">
                  <div className="">PnL Chart</div>
                  <div className="flex gap- text-base items-center">
                    <div
                      onClick={() => setPnlChartType("percentage")}
                      className={` rounded-l-md border w-16 text-center border-[#A1A1A1] cursor-pointer px-3 ${
                        pnlChartType === "percentage"
                          ? "bg-tertiary text-black font-bold border-tertiary drop-shadow-xl"
                          : "bg-[#]"
                      }`}
                    >
                      PnL%
                    </div>
                    <div
                      onClick={() => setPnlChartType("pnl")}
                      className={` rounded-r-md border w-16 text-center border-[#A1A1A1] cursor-pointer px-3 ${
                        pnlChartType === "pnl"
                          ? "bg-tertiary text-black font-bold border-tertiary drop-shadow-xl"
                          : "bg-[#]"
                      }`}
                    >
                      PnL
                    </div>
                  </div>
                </div>
                <select
                  value={graphTimeScale}
                  onChange={(e) => setGraphTimeScale(e.target.value)}
                  className="form-select bg-transparent border pr-4 pl-1 py-2 border-[#6E6E6E]  rounded-lg text-tertiary"
                >
                  <option className="bg-[#202020]" value="week">
                    Last 7 Days
                  </option>
                  <option className="bg-[#202020]" value="month">
                    Last Month
                  </option>
                  <option className="bg-[#202020]" value="year">
                    Last Year
                  </option>
                  <option className="bg-[#202020]" value="all">
                    All
                  </option>
                </select>
              </div>
              {pnlChartType === "percentage" ? (
                <Chart
                  options={options2}
                  ref={chartRef}
                  series={options2.series}
                  type="line"
                  width="100%"
                />
              ) : (
                <Chart
                  options={options}
                  ref={chartRef}
                  series={options.series}
                  type="line"
                  width="100%"
                />
              )}
            </div>
          </div>
          <div className="mt-4 bg-[#202020] px-8 py-4 rounded-3xl">
            <div className="flex justify-between items-start mb-2">
              <div className="flex flex-col justify-start gap-4 text-[#A1A1A1] pl-4 text-lg">
                <div>Daily PnL</div>
                <div className="flex gap- text-base items-center">
                  <div
                    onClick={() => setPnlMeterType("percentage")}
                    className={` rounded-l-md border w-16 text-center border-[#A1A1A1] cursor-pointer px-3 ${
                      pnlMeterType === "percentage"
                        ? "bg-tertiary text-black font-bold border-tertiary drop-shadow-xl"
                        : "bg-[#]"
                    }`}
                  >
                    PnL%
                  </div>
                  <div
                    onClick={() => setPnlMeterType("pnl")}
                    className={` rounded-r-md border w-16 text-center border-[#A1A1A1] cursor-pointer px-3 ${
                      pnlMeterType === "pnl"
                        ? "bg-tertiary text-black font-bold border-tertiary drop-shadow-xl"
                        : "bg-[#]"
                    }`}
                  >
                    PnL
                  </div>
                </div>
              </div>
              {/* <select
                value={meterTimeScale}
                onChange={(e) => setMeterTimeScale(e.target.value)}
                className="form-select bg-transparent border pr-4 pl-1 py-2 border-[#6E6E6E]  rounded-lg text-tertiary"
              >
                <option className="bg-[#202020]" value="daily">
                  Daily
                </option>
                <option className="bg-[#202020]" value="week">
                  Last 7 Days
                </option>
                <option className="bg-[#202020]" value="month">
                  Last Month
                </option>
                <option className="bg-[#202020]" value="year">
                  Last Year
                </option>
                <option className="bg-[#202020]" value="all">
                  All
                </option>
              </select> */}
            </div>
            <div className="flex min-[450px]:hidden justify-center bg-[#202020] rounded-xl px-4 pt-4 ">
              {/* PnL meter */}
              {pnlMeterType === "percentage" ? (
                <ReactSpeedometer
                  width={300}
                  height={200}
                  needleHeightRatio={0.4}
                  maxSegmentLabels={0}
                  segments={6}
                  value={
                    netPer !== undefined
                      ? netPer >= 0
                        ? netPer <= 1000
                          ? netPer
                          : 1000
                        : netPer >= -1000
                        ? netPer
                        : -1000
                      : initialPerData?.[initialPerData.length - 1]?.[1]
                  }
                  currentValueText={`
                ${
                  initialPerData?.[initialPerData.length - 1]?.[1] > 0
                    ? "+"
                    : ""
                }${
                    netPer !== undefined
                      ? netPer + "%"
                      : initialPerData?.[initialPerData.length - 1]?.[1] + "%"
                  }`}
                  maxValue={1000}
                  minValue={-1000}
                  forceRender={true}
                  startColor={"#FF4A1A"}
                  endColor={"#31AB36"}
                  paddingHorizontal={17}
                  paddingVertical={27}
                  // segmentColors={['#E83838', '#5DBC55']}
                  needleColor="lightgreen"
                  textColor="white"
                  valueTextFontSize={"30px"}
                  // paddingVertical={'10px'}
                />
              ) : (
                <ReactSpeedometer
                  width={300}
                  height={200}
                  needleHeightRatio={0.4}
                  maxSegmentLabels={0}
                  segments={6}
                  value={
                    netPnl !== undefined
                      ? netPnl >= 0
                        ? netPnl <= 50000
                          ? netPnl
                          : 50000
                        : netPnl >= -50000
                        ? netPnl
                        : -50000
                      : initialPnlData?.[initialPnlData.length - 1]?.[1]
                  }
                  currentValueText={`
                ${
                  initialPnlData?.[initialPnlData.length - 1]?.[1] > 0
                    ? "+"
                    : ""
                }${
                    netPnl !== undefined
                      ? netPnl
                      : initialPnlData?.[initialPnlData.length - 1]?.[1]
                  }`}
                  maxValue={50000}
                  minValue={-50000}
                  forceRender={true}
                  startColor={"#FF4A1A"}
                  endColor={"#31AB36"}
                  paddingHorizontal={17}
                  paddingVertical={27}
                  // segmentColors={['#E83838', '#5DBC55']}
                  needleColor="lightgreen"
                  textColor="white"
                  valueTextFontSize={"30px"}
                  // paddingVertical={'10px'}
                />
              )}
            </div>
            <div className="hidden min-[450px]:flex justify-center bg-[#202020] rounded-xl px-4 pt-4 ">
              {/* PnL meter */}

              {pnlMeterType === "percentage" ? (
                <ReactSpeedometer
                  width={300}
                  height={200}
                  needleHeightRatio={0.4}
                  maxSegmentLabels={0}
                  segments={6}
                  value={
                    netPer !== undefined
                      ? netPer >= 0
                        ? netPer <= 1000
                          ? netPer
                          : 1000
                        : netPer >= -1000
                        ? netPer
                        : -1000
                      : initialPerData?.[initialPerData.length - 1]?.[1]
                  }
                  currentValueText={`
                ${
                  initialPerData?.[initialPerData.length - 1]?.[1] > 0
                    ? "+"
                    : ""
                }${
                    netPer !== undefined
                      ? netPer + "%"
                      : initialPerData?.[initialPerData.length - 1]?.[1] + "%"
                  }`}
                  maxValue={1000}
                  minValue={-1000}
                  forceRender={true}
                  startColor={"#FF4A1A"}
                  endColor={"#31AB36"}
                  paddingHorizontal={17}
                  paddingVertical={27}
                  // segmentColors={['#E83838', '#5DBC55']}
                  needleColor="lightgreen"
                  textColor="white"
                  valueTextFontSize={"30px"}
                  // paddingVertical={'10px'}
                />
              ) : (
                <ReactSpeedometer
                  width={300}
                  height={200}
                  needleHeightRatio={0.4}
                  maxSegmentLabels={0}
                  segments={6}
                  value={
                    netPnl !== undefined
                      ? netPnl >= 0
                        ? netPnl <= 50000
                          ? netPnl
                          : 50000
                        : netPnl >= -50000
                        ? netPnl
                        : -50000
                      : initialPnlData?.[initialPnlData.length - 1]?.[1]
                  }
                  currentValueText={`
                ${
                  initialPnlData?.[initialPnlData.length - 1]?.[1] > 0
                    ? "+"
                    : ""
                }${
                    netPnl !== undefined
                      ? netPnl
                      : initialPnlData?.[initialPnlData.length - 1]?.[1]
                  }`}
                  minValue={-50000}
                  maxValue={50000}
                  forceRender={true}
                  startColor={"#FF4A1A"}
                  endColor={"#31AB36"}
                  paddingHorizontal={17}
                  paddingVertical={27}
                  // segmentColors={['#E83838', '#5DBC55']}
                  needleColor="lightgreen"
                  textColor="white"
                  valueTextFontSize={"30px"}
                  // paddingVertical={'10px'}
                />
              )}
            </div>
          </div>
          <div className="mt-4 bg-[#202020] px-8 py-4 rounded-3xl">
            <div className="flex justify-between items-center">
              <div className="text-lg text-[#A1A1A1]">My Trades</div>
              <select
                value={tableTimeScale}
                onChange={(e) => setTableTimeScale(e.target.value)}
                className="form-select bg-transparent border pr-4 pl-1 py-2 border-[#6E6E6E]  rounded-lg text-tertiary"
              >
                <option className="bg-[#202020]" value="week">
                  Last 7 Days
                </option>
                <option className="bg-[#202020]" value="month">
                  Last Month
                </option>
                <option className="bg-[#202020]" value="year">
                  Last Year
                </option>
                <option className="bg-[#202020]" value="all">
                  All
                </option>
              </select>
            </div>
            <div className="mt-6">
              <div className="flex flex-col justify-between border- pb-2 border-b-[#373737]">
                {btoStoreFilteredData?.map((item, index) => (
                  <div>
                    <Accordion index={index} item={item} />
                  </div>
                ))}
              </div>
              {btoStoreFilteredData?.length === 0 && (
                <div className="flex w-full justify-center items-center h-48 text-[#7b7B7B] font-semibold text-xl">
                  No Bto's available
                </div>
              )}
              {/* {btoData?.map((item, index) => (
              <div className="flex justify-between pt-4 text-white">
                <div className="text-white w-[36%]">
                  {new Date(item?.createdAt).toLocaleDateString(
                    "en-GB",
                    dateOptions
                  )}
                </div>
                <div className="text-[#5F6868 w-[38%]">{item?.closingQuote}</div>
                <div className="text-[#5F6868 w-[20%]">
                  {item?.pnl}
                </div>
              </div>
            ))} */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Statistics;
