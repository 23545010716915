// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0; 
}

/* Prevent scrolling but allow interaction */
input[type=number] {
    pointer-events: none;
}

/* Reset pointer-events for the input itself */
input[type=number]:not(:disabled) {
    pointer-events: auto;
}`, "",{"version":3,"sources":["webpack://./src/customStyles/model.css"],"names":[],"mappings":"AAAA;;IAII,gBAAgB;IAChB,SAAS;AACb;;AAEA,4CAA4C;AAC5C;IACI,oBAAoB;AACxB;;AAEA,8CAA8C;AAC9C;IACI,oBAAoB;AACxB","sourcesContent":["input[type=number]::-webkit-inner-spin-button, \ninput[type=number]::-webkit-outer-spin-button { \n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n    margin: 0; \n}\n\n/* Prevent scrolling but allow interaction */\ninput[type=number] {\n    pointer-events: none;\n}\n\n/* Reset pointer-events for the input itself */\ninput[type=number]:not(:disabled) {\n    pointer-events: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
