import Home from "./pages/Home"
import SignIn from "./pages/Authentication/Login";

import Header from "./components/Header/Header"
import Footer from "./components/Footer"

import Model from "./pages/User/Model";
import Statistics from "./pages/User/Statistics";
import Profile from "./pages/User/Profile";
import UserInfo from "./pages/Admin/UserInfo"
import UserHistory from "./pages/Admin/UserHistory";
import Leaderboard from "./pages/User/Leaderboard";
import Error from "./pages/Error/index"

const routes = [
  {
    exact: true,
    isPublic: true,
    path: "/",
    name: "home",
    element: (
      <>
        <Header />
        <Home />
        <Footer />
      </>
    ),
  },
  {
    exact: true,
    isPublic: true,
    path: "/signIn",
    name: "signIn",
    element: (
      <>
        <Header />
        <SignIn />
      </>
    ),
  },
  // {
  //   exact: true,
  //   isPublic: true,
  //   path: "/signUp",
  //   name: "signUp",
  //   element: (
  //     <>
  //       <Header />
  //       <SignUp />
  //     </>
  //   ),
  // },
  {
    exact: true,
    isPublic: false,
    path: "/model",
    name: "model",
    element: <Model />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/statistics",
    name: "statistics",
    element: <Statistics />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/leaderboard",
    name: "leaderboard",
    element: <Leaderboard />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/profile",
    name: "profile",
    element: <Profile />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/admin/userinfo",
    name: "userinfo",
    element: <UserInfo />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/admin/userhistory/:uid",
    name: "userinfo",
    element: <UserHistory />,
  },
  {
    exact: true,
    isPublic: true,
    path: "/*",
    name: "Error Page",
    element: <Error />,
  }
  //   {
  //     exact: true,
  //     isPublic: true,
  //     path: "/contact-us",
  //     name: "contact-us",
  //     element: <ContactUs />,
  //   },
];

export default routes;