import React from "react";
import title from "../../assets/alertsify.svg";
import { Link } from "react-router-dom";

const index = () => {
  return (
    <div className="bg-[#202020] relative">
      <div className="py-16 w-[60%] sm:w-[80%] mx-auto flex sm:flex-row flex-col sm:items-start items- sm:justify-evenly">
        {/* <div className="sm:w-1/3 w-full flex flex-col items-end mr-4 gap-y-4">
          <div className="text-[#FFFFFF] text-3xl font-semibold sm:text-left text-center">
            <img className="sm:h-[84px] h-[20px] rounded-2xl" src={title} />
          </div>
        </div> */}
        <div className="sm:w-1/3 w-full flex sm:flex-row flex-col items-center gap-x-8 gap-y-4">
          <img className="sm:h-[84px] h-[80px] rounded-2xl" src={title} />
          <p className="text-[#FFFFFF] opacity-50 text-sm sm:text-left text-center">
            Alertsify – Compete, Conquer, and Cash-In!
          </p>{" "}
        </div>
        <div className="flex flex-col sm:flex-row  sm:w-1/3 w-full pt- items-center">
          <div className="flex sm:gap-x-8 sm:gap-y-0 gap-y-8 sm:items-start sm:justify-evenly justify-center text-center sm:text-left mt-4 sm:mt-0 sm:flex-nowrap flex-wrap  w-full ">
            <div className="flex flex-col gap-y-4 sm:w-1/3 w-1/2">
              <h4 className="text-white font-bold text-lg">Dashboard</h4>
              <div className="text-[#FFFFFF] opacity-50 text-sm gap-y-2 flex flex-col">
                <Link to="/model">Model</Link>
                <Link to="/statistics">User History</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-[#FFFFFF] opacity-50 text-sm flex justify-center py-4 border-t-[1px] border-[#FFFFFF] w-full">
        <p>Copyright © 2024 All rights reserved.</p>
      </div>
    </div>
  );
};

export default index;
