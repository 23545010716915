import React, { useEffect, useState } from "react";
import { userStore } from "../../store/user";
import Loader from "../../components/Loader";

import bgLeft from "../../assets/bgLeftRectangle.png";
import bgPhoneTop from "../../assets/bgPhoneTopRectangle.png";
import profile from "../../assets/defaultPicture.png";
// import phoneProfile from "../../assets/defaultPhonePicture.png";
import download from "../../assets/icons/downloads.png";
import reset from "../../assets/icons/reset.png";
import daily from "../../assets/icons/daily.png";
import monthly from "../../assets/icons/monthly.png";

import { IoSearchSharp } from "react-icons/io5";

import { MdPerson } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { IoIosArrowForward } from "react-icons/io";
import { RiShareBoxFill } from "react-icons/ri";
import axios from "axios";
import AdminSidebar from "../../components/Sidebar/Admin/AdminSidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Papa from "papaparse";
import { getChannelsOfServer } from "../../API/discord";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const UserInfo = () => {
  const { userData } = userStore((state) => state);

  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState([]);
  const [filteredUserInfo, setFilteredUserInfo] = useState([]);
  const [userSearch, setUserSearch] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [bigNavToggle, setBigNavToggle] = useState(false);

  const [extractedData, setExtractedData] = useState();
  const [resetLeaderboard, setResetLeaderboard] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  const [publishDailyStats, setPublishDailyStats] = useState(false);
  const [publishWeeklyStats, setPublishWeeklyStats] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [publishUserId, setPublishUserId] = useState();

  const navigate = useNavigate();
  const gentradeToken = localStorage.getItem("gentradeToken");
  const {
    avatarUrl,
    discordServers = [],
    name,
    id: userId,
    role,
  } = userData || {};

  useEffect(() => {
    if (userData?.id) {
      if (role == "admin") {
        const getUserInfo = async () => {
          await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_BASE_URL}/user/getAllUsers`,
            headers: {
              Authorization: "Bearer " + gentradeToken,
            },
          })
            .then((response) => {
              // console.log(response.data);
              setUserInfo(response.data);
              setFilteredUserInfo(response.data);
              // setIsLoading(false)
              const extractFields = (data) => {
                return {
                  name: data.name,
                  email: data.email,
                  joiningDate: data.createdAt,
                  currentBalance: data.currentBalance,
                };
              };

              // Extract fields from each object in the response
              setExtractedData(response?.data?.map(extractFields));
            })
            .catch((error) => {
              console.log(error);
              // setIsLoading(false)
              toast("Something went wrong, please try again later", {
                type: "er",
              });
              navigate("/model");
            })
            .finally(() => {
              setIsLoading(false);
            });
        };
        getUserInfo();
      } else navigate("/model");
    }
  }, [userData]);

  useEffect(() => {
    if (userSearch.length > 0) {
      let tempArray = [...userInfo];
      tempArray = tempArray.filter((user) => {
        return user.name.toLowerCase().includes(userSearch.toLowerCase());
      });
      setFilteredUserInfo(tempArray);
    }
    if (userSearch.length === 0) {
      setFilteredUserInfo(userInfo);
    }
  }, [userSearch]);

  useEffect(() => {
    if (isOpen) {
      // Prevent scrolling of the body when the navbar is open
      document.body.style.overflow = "hidden";
    } else {
      // Restore scrolling when the navbar is closed
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  // Function to download CSV
  const downloadCSV = (filename) => {
    if (!extractedData) {
      return toast.error("something went wrong. Please try again later");
    }

    const csv = Papa?.unparse(extractedData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleLeaderboardReset = async () => {
    if (role !== "admin") {
      return;
    }
    setResetLoading(true);
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_BASE_URL}/pnl/resetLeaderboard`,
      headers: {
        Authorization: "Bearer " + gentradeToken,
      },
    })
      .then((response) => {
        toast.success("Leaderboard reset successfully");
        setResetLoading(false);
        setResetLeaderboard(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
        setResetLoading(false);
      });
  };

  const handlePublishDailyStats = async () => {
    if (role !== "admin") {
      return;
    }
    setPublishLoading(true);
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE_URL}/user/publishDailyStats`,
      headers: {
        Authorization: "Bearer " + gentradeToken,
      },
      data: publishUserId
        ? {
            serverId: discordServerId,
            channelId: discordChannelId,
            userId: publishUserId,
          }
        : {
            serverId: discordServerId,
            channelId: discordChannelId,
          },
    })
      .then((response) => {
        toast.success("Daily stats published successfully!");
        setPublishLoading(false);
        setPublishDailyStats(false);
        setPublishUserId(null);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
        setPublishDailyStats(false);
        setPublishUserId(null);
      });
  };

  const handlePublishWeeklyStats = async () => {
    if (role !== "admin") {
      return;
    }
    setPublishLoading(true);
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE_URL}/user/publishWeeklyStats`,
      headers: {
        Authorization: "Bearer " + gentradeToken,
      },
      data: publishUserId
        ? {
            serverId: discordServerId,
            channelId: discordChannelId,
            userId: publishUserId,
          }
        : {
            serverId: discordServerId,
            channelId: discordChannelId,
          },
    })
      .then((response) => {
        toast.success("Weekly stats published successfully!");
        setPublishLoading(false);
        setPublishWeeklyStats(false);
        setPublishUserId(null);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
        setPublishWeeklyStats(false);
        setPublishUserId(null);
      });
  };

  //For fetching discord channels and server
  const [discordServerId, setDiscordServerId] = useState("");
  const [discordChannels, setDiscordChannels] = useState([]);
  const [showBotInvitationLink, setShowBotInvitationLink] = useState(false);
  const [discordChannelId, setDiscordChannelId] = useState("");
  const [loading, setLoading] = useState(false);
  const { selectedDiscordChannel, setSelectedDiscordChannel } = userStore(
    (state) => state
  );
  const { currentDiscordServer } = userData || {};

  const setDataInLocalStorage = (name, key, value) => {
    const data = JSON.parse(localStorage.getItem(name) || `{}`);
    data[key] = value;
    localStorage.setItem(name, JSON.stringify(data));
  };

  useEffect(() => {
    if (discordServers?.length) {
      try {
        // let discordGuildId = discordServers[0].id;
        let discordGuildId = currentDiscordServer.id;
        console.log(discordGuildId, discordServers);
        setDiscordServerId(discordGuildId);
        setDataInLocalStorage(
          "btoMetaData",
          "discordServerId",
          discordGuildId.toString()
        );
        (async () => {
          const channelsResponse = await getChannelsOfServer(
            discordGuildId,
            gentradeToken
          );
          console.log(channelsResponse);
          // setDiscordChannels([channelsResponse.data?.[0]]);
          setDiscordChannels(channelsResponse.data);
          setDiscordChannelId(channelsResponse.data?.[0]?.id);
          setDataInLocalStorage(
            "btoMetaData",
            "discordChannelId",
            channelsResponse.data?.[0]?.id
          );
          setDataInLocalStorage(
            "cachedDiscordChannels",
            discordGuildId,
            channelsResponse.data
          );
        })();
      } catch (err) {
        console.log(err);
        toast(
          err.response?.data || "Something went wrong, please try again later",
          {
            type: "error",
          }
        );
      }
    }
  }, [discordServers]);

  return (
    <div className="min-h-screen flex flex-col lg:flex-row">
      {(resetLeaderboard || publishDailyStats || publishWeeklyStats) && (
        <div
          onClick={() => {
            setResetLeaderboard(false);
            setPublishDailyStats(false)
            setPublishWeeklyStats(false)
            setPublishUserId(null);
          }}
          className={`fixed top-0 left-0 h-full w-full bg-black bg-opacity-60 z-30
            ${resetLoading || publishLoading ? "pointer-events-none" : ""}
          `}
        />
      )}
      {publishDailyStats && (
        <div className="w-[40%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-[#191919] rounded-lg py-6 px-6">
          <div className="text-2xl font-semibold text-white">
            Publish Daily Stats
          </div>
          <div className="w-full h-[1px] mt-6" />
          <div className="flex mt-8 lg:mt-0 text-white flex-col md:flex-ro w gap-4">
            <div className="flex items-center gap-2">
              <label className="w-36">Discord Servers:</label>
              <select
                className="bg-[#424242] px-2 py-1 rounded-md"
                value={currentDiscordServer?.id}
                onChange={(e) => {
                  setDiscordServerId(e.target.value);
                  setDataInLocalStorage(
                    "btoMetaData",
                    "discordServerId",
                    e.target.value
                  );
                }}
              >
                <option value={currentDiscordServer?.id}>
                  {currentDiscordServer?.name}
                </option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              <label className="w-36">Discord Channels:</label>
              {loading ? (
                <div className="flex gap-2 items-center">
                  Fetching
                  <AiOutlineLoading3Quarters className="animate-spin" />{" "}
                </div>
              ) : (
                <select
                  className="bg-[#424242] px-2 py-[2px] rounded-md"
                  value={discordChannelId}
                  onChange={(e) => {
                    setDiscordChannelId(e.target.value);
                    setSelectedDiscordChannel(e.target.value);
                    setDataInLocalStorage(
                      "btoMetaData",
                      "discordChannelId",
                      e.target.value
                    );
                  }}
                >
                  {discordChannels.map((d, i) => (
                    <option value={d.id} key={i}>
                      {d.name}
                    </option>
                  ))}{" "}
                </select>
              )}
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              disabled={publishLoading}
              onClick={() => {
                setPublishDailyStats(false);
                setPublishUserId(null);
              }}
              className="disabled:bg-[#737B7B]  bg-red-700 text-white py-1 w-28 rounded-md "
            >
              Cancel
            </button>
            <button
              disabled={publishLoading}
              onClick={handlePublishDailyStats}
              className="border border-tertiary [#737B7B] py-1 w-28 text-black bg-tertiary [#5F6868] rounded-md font-semibold"
            >
              Publish
            </button>
          </div>
        </div>
      )}
      {publishWeeklyStats && (
        <div className="w-[40%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-[#191919] rounded-lg py-6 px-6">
          <div className="text-2xl font-semibold  text-white">
            Publish Weekly Stats
          </div>
          <div className="w-full h-[1px] mt-6" />
          <div className="flex mt-8 lg:mt-0 text-white flex-col md:flex-ro w gap-4">
            <div className="flex items-center gap-2">
              <label className="w-36">Discord Servers:</label>
              <select
                className="bg-[#424242] px-2 py-1 rounded-md"
                value={currentDiscordServer?.id}
                onChange={(e) => {
                  setDiscordServerId(e.target.value);
                  setDataInLocalStorage(
                    "btoMetaData",
                    "discordServerId",
                    e.target.value
                  );
                }}
              >
                <option value={currentDiscordServer?.id}>
                  {currentDiscordServer?.name}
                </option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              <label className="w-36">Discord Channels:</label>
              {loading ? (
                <div className="flex gap-2 items-center">
                  Fetching
                  <AiOutlineLoading3Quarters className="animate-spin" />{" "}
                </div>
              ) : (
                <select
                  className="bg-[#424242] px-2 py-[2px] rounded-md"
                  value={discordChannelId}
                  onChange={(e) => {
                    setDiscordChannelId(e.target.value);
                    setSelectedDiscordChannel(e.target.value);
                    setDataInLocalStorage(
                      "btoMetaData",
                      "discordChannelId",
                      e.target.value
                    );
                  }}
                >
                  {discordChannels.map((d, i) => (
                    <option value={d.id} key={i}>
                      {d.name}
                    </option>
                  ))}{" "}
                </select>
              )}
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              disabled={publishLoading}
              onClick={() => {
                setPublishWeeklyStats(false);
                setPublishUserId(null);
              }}
              className="disabled:bg-[#737B7B]  bg-red-700 text-white py-1 w-28 rounded-md "
            >
              Cancel
            </button>
            <button
              disabled={publishLoading}
              onClick={handlePublishWeeklyStats}
              className="border border-tertiary [#737B7B] py-1 w-28 text-black bg-tertiary [#5F6868] rounded-md font-semibold"
            >
              Publish
            </button>
          </div>
        </div>
      )}
      {resetLeaderboard && (
        <div className="w-[40%] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 bg-[#191919] rounded-lg py-6 px-6">
          <div className="text-xl font-light semibold text-white">
            Reset Leaderboard
          </div>
          <div className="w-full h-[1px]" />
          <div className="text-[#5F6868] mt-6">
            Would you like to reset the Leaderboard?
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              disabled={resetLoading}
              onClick={() => setResetLeaderboard(false)}
              className="border border-[#737B7B] py-1 w-28 text-[#5F6868] rounded-md"
            >
              Cancel
            </button>
            <button
              disabled={resetLoading}
              onClick={handleLeaderboardReset}
              className="disabled:bg-[#737B7B]  bg-red-700 text-white py-1 w-28 rounded-md "
            >
              Reset
            </button>
          </div>
        </div>
      )}
      <AdminSidebar
        bigNavToggle={bigNavToggle}
        setbigNavToggle={setBigNavToggle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        route={"userInfo"}
      />

      {/* Body for larger screens */}
      <div
        className={`hidden lg:block ${
          bigNavToggle ? "lg:w-[80%]" : "lg:w-[96%]"
        } bg-cover bg-no-repeat bg-left px-12 py-8 text-[15px]`}
        style={{ backgroundImage: `url(${bgLeft})` }}
      >
        {/* Search bar and profile picture*/}
        <div className="flex gap-4 justify-between items-center w-full">
          <div className="flex gap-2 items-center w-full rounded-lg border border-[#646464] h-[40px] px-4 text-[#737B7B] placeholder:text-[#5a5a5a]">
            <IoSearchSharp size={25} />
            <input
              className="bg-transparent w-full outline-none text-[#9b9b9b] placeholder:text-[#5a5a5a] font-semibold"
              value={userSearch}
              onChange={(e) => setUserSearch(e.target.value)}
              placeholder="Search User"
            />
          </div>
          <div className="flex">
            <div className="flex justify-end w-[190px]">
              <Link
                to={"/model"}
                className="text-tertiary px-2 py-1 border border-[#646464] text-center rounded-lg"
              >
                Dashboard
              </Link>
            </div>
            <img
              src={avatarUrl || profile}
              alt="profile"
              className="ml-6 border border-white rounded-full size-[34px]"
            />
          </div>
        </div>

        <div className="bg-[#202020] overflow-y-auto scrollbar h-[620px h-[78vh] rounded-xl px-8 pb-4 mt-10">
          {isLoading ? (
            <div className="text-4xl h-full text-white pb-8 flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <table className="bg-transparent w-full ">
              <thead className="text-[#5F6868] bg-[#202020] sticky top-0 ">
                <tr className="border-b border-b-[#373737] ">
                  <th className="px-4 py-4 text-left w-[20%]=">
                    <div className="flex items-center gap-2 ">
                      <MdPerson size={20} className="text-tertiary" />
                      Name
                    </div>
                  </th>
                  <th className="px-4 py-4 text-left w-[25%]">
                    <div className="flex items-center gap-2 ">
                      <MdOutlineMail size={20} className="text-tertiary" />
                      Email
                    </div>
                  </th>
                  <th className="px-4 py-4 text-left w-[15%]">
                    <div className="flex items-center gap-2 ">
                      <SlCalender size={18} className="text-tertiary" />
                      Joining Date
                    </div>
                  </th>
                  <th className="px-4 py-4 text-left w-[12%]">
                    <div className="flex items-center gap-2 ">
                      <img src={daily} className="size-5" />
                      Daily Stats
                    </div>
                  </th>
                  <th className="px-4 py-4 text-left w-[12%]">
                    <div className="flex items-center gap-2 ">
                      <img src={monthly} className="size-5" />
                      Weekly Stats
                    </div>
                  </th>
                  <th className="px-4 py-4 text-left w-[5%}"></th>
                </tr>
              </thead>
              <tbody className="">
                {filteredUserInfo.map((user, index) => (
                  <tr
                    key={index}
                    className={`cursor-pointer hover:bg-[#191919]`}
                    onClick={() => navigate(`/admin/userhistory/${user.id}`)}
                  >
                    <td className="px-4 py-2 text-[#737B7B] w-[20%]">
                      {user.name}
                    </td>
                    <td className="px-4 py-2 text-[#686F6F] w-[25%]">
                      {user.email}
                    </td>
                    <td className="px-4 py-2 text-[#28A263] w-[15%]">
                      <div className="flex bg-[#242424] w-[120px] rounded-lg justify-center py-1">
                        {user.createdAt.split("T")?.[0]}
                      </div>
                    </td>
                    <td className="px-4 py-2 text-[#28A263] w-[12%]">
                      <div
                        className="flex bg-tertiary text-black font-semibold [#242424] w-[120px] rounded-lg justify-center py-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPublishDailyStats(true);
                          setPublishUserId(user.id);
                        }}
                      >
                        Publish
                      </div>
                    </td>
                    <td className="px-4 py-2 text-[#28A263] w-[12%]">
                      <div
                        className="flex bg-tertiary text-black font-semibold [#242424] w-[120px] rounded-lg justify-center py-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPublishWeeklyStats(true);
                          setPublishUserId(user.id);
                        }}
                      >
                        Publish
                      </div>
                    </td>
                    <td className="px-4 py-2 text-[#737B7B] w-[5%]">
                      <IoIosArrowForward />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div className="flex justify-end gap-4 mt-4">
          <button
            onClick={() => setPublishDailyStats(true)}
            className="flex items-center gap-2 text-white [#646464] px-4 py-2 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
          >
            <img src={daily} className="size-4" />
            Publish Daily Stats
          </button>
          <button
            onClick={() => setPublishWeeklyStats(true)}
            className="flex items-center gap-2 text-white [#646464] px-4 py-2 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
          >
            <img src={monthly} className="size-4" />
            Publish Weekly Stats
          </button>
          <button
            onClick={() => setResetLeaderboard(true)}
            className="flex items-center gap-2 text-white [#646464] px-4 py-2 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
          >
            <img src={reset} className="size-4" />
            Reset Leaderboard
          </button>
          <button
            onClick={() => downloadCSV("UserInfo")}
            className="flex items-center gap-2 text-white [#646464] px-4 py-2 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
          >
            <img src={download} className="size-4" />
            Download User Info
          </button>
        </div>
      </div>

      {/* Body for smaller screens */}
      <div
        className="lg:hidden min-h-screen w-full bg-cover bg-center bg-no-repeat px-4 flex gap-4 flex-col pt-8"
        style={{ backgroundImage: `url(${bgPhoneTop})` }}
        onClick={() => setIsOpen(false)}
      >
        <div className="flex gap-2 items-center w-full rounded-lg border border-[#646464] h-[40px] px-4 text-[#737B7B] placeholder:text-[#5a5a5a]">
          <IoSearchSharp size={25} />
          <input
            className="bg-transparent outline-none text-[#9b9b9b] placeholder:text-[#5a5a5a] font-semibold"
            value={userSearch}
            onChange={(e) => setUserSearch(e.target.value)}
            placeholder="Search User"
          />
        </div>
        <div className="flex justify- nd gap-4 flex-wrap">
          <button
            onClick={() => setPublishDailyStats(true)}
            className="flex items-center gap-2 text-white [#646464] px-4 py-2 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
          >
            <img src={daily} className="size-4" />
            Publish Daily Stats
          </button>
          <button
            onClick={() => setPublishWeeklyStats(true)}
            className="flex items-center gap-2 text-white [#646464] px-4 py-2 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
          >
            <img src={monthly} className="size-4" />
            Publish Weekly Stats
          </button>
          <button
            onClick={() => setResetLeaderboard(true)}
            className="flex items-center gap-2 text-white [#646464] px-4 py-2 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
          >
            <img src={reset} className="size-4" />
            Reset Leaderboard
          </button>
          <button
            onClick={() => downloadCSV("UserInfo")}
            className="flex items-center gap-2 text-white [#646464] px-4 py-2 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
          >
            <img src={download} className="size-4" />
            Download User Info
          </button>
        </div>

        {isLoading ? (
          <div className="text-4xl h-[80vh] text-white pb-8 flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <div className="flex flex-col gap-4 pb-4">
            {filteredUserInfo.map((user, index) => (
              <div
                className="bg-[#202020] px-4 py-4 rounded-xl"
                onClick={() => navigate(`/admin/userhistory/${user.id}`)}
              >
                <div className="text-[#737B7B] font-semibold text-[18.5px]">
                  {user.name}
                </div>
                <div className="text-[#686F6F] font-medium text-[17px] mt-2">
                  {user.email}
                </div>
                <div className="flex justify-between mt-2">
                  <div className="text-[#28A263] bg-[#242424] rounded-lg px-4 py-2 w-[120px] font-medium">
                    {user.createdAt.split("T")?.[0]}
                  </div>
                  <RiShareBoxFill size={25} className="text-white" />
                </div>
                <div className="flex flex-col justify-between mt-4 gap-2">
                  <div
                    className="flex bg-tertiary text-black font-semibold [#242424] w-fit [120px] rounded-lg justify-center px-2 py-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      setPublishDailyStats(true);
                      setPublishUserId(user.id);
                    }}
                  >
                    Publish Daily Stats
                  </div>
                  <div
                    className="flex bg-tertiary text-black font-semibold [#242424] w-fit [120px] rounded-lg justify-center px-2 py-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      setPublishWeeklyStats(true);
                      setPublishUserId(user.id);
                    }}
                  >
                    Publish Weekly Stats
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
