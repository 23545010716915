import React from "react";

const index = ({error, resetErrorBoundary}) => {
    console.log(error);
  return (
    <div class="min-h-screen flex flex-grow items-center justify-center bg-[#101010]">
      <div class="rounded-lg bg-[#202020] p-8 text-center shadow-xl">
        <h1 class="mb-4 text-4xl font-bold text-white">404</h1>
        <p class="text-[rgb(196,196,196)]">
          Oops! The page you are looking for could not be found.
        </p>
        <a
          href="/"
          class="mt-4 inline-block rounded bg-[#b8e834] px-4 py-2 font-semibold text-black hover:bg-[#aad13f]"
        >
          {" "}
          Go back to Home{" "}
        </a>
      </div>
    </div>
  );
};

export default index;
