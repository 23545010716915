import axios from "axios";

export const getUser = (token) =>
  axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/getInfo`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

export const getOptionContracts = (values, key) => {
  if (!key) throw "Provide key";
  const baseUrl = "https://api.polygon.io/v3/reference/options/contracts";
  const url = new URL(baseUrl);

  url.searchParams.append("underlying_ticker", values.ticker);
  url.searchParams.append("apiKey", key);
  url.searchParams.append("contract_type", values.contractType || "call");
  url.searchParams.append("sort", values.sort || "expiration_date");
  url.searchParams.append("limit", values.limit || "1000");
  return axios.get(url.href);
};
  