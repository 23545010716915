import React, { useEffect, useState, memo } from "react";
import { userStore } from "../store/user";
import { formatter } from "../helper";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function PnlColumn({ activeTab, isMobile = false, btoItem, socketLoading, alterSocketLoading }) {
  const {
    socket,
    btoData: { active = [], expired = [], sold },
    setPnlData
  } = userStore((state) => state);
  const [socketPnlData, setSocketPnlData] = useState({});
  useEffect(() => {
    if (!socket) return;
    socket.on("pnl_stream", (message) => {
      if (activeTab == "expired") setSocketPnlData({});
      else {
        setSocketPnlData(message);
        // localStorage.setItem("pnlData", JSON.stringify(message)); // storing the pnlData in localStorage to get the current quote in BtoData componenet while selling the contracts
        setPnlData(message)
        alterSocketLoading(false);
      }
    });
  }, [socket]);
  const data =
    activeTab == "active" ? active : activeTab == "sold" ? sold : expired;
  const btoPnlFromSocket = socketPnlData[btoItem?.id] || {};
  return isMobile ? (
    <div className="flex gap-2 items-center">
      {activeTab === "active" ? (
      <div className="flex gap-2">
        <span className="text-tertiary">{activeTab == "active" ? "Current" : "Exit"}{" "}</span>
        <span
          className={`text-[15px] ${
            activeTab == "active" && btoPnlFromSocket.quote
              ? btoPnlFromSocket.quote > btoItem.quote
                ? "text-green-500"
                : btoPnlFromSocket.quote == btoItem.quote
                ? "text-yellow-500"
                : "text-red-500"
              : btoItem?.selling_quote > btoItem?.quote
              ? "text-green-500"
              : btoItem?.selling_quote === btoItem?.quote
              ? "text-yellow-500"
              : btoItem?.selling_quote < btoItem.quote && "text-red-500"
          }`}
        >
          {/* {btoPnlFromSocket?.quote || btoItem?.selling_quote
            ? btoPnlFromSocket?.quote || btoItem?.selling_quote
            : "NA"} */}
            {(btoPnlFromSocket?.quote !== undefined) || (btoItem?.selling_quote !== undefined)
            ? btoPnlFromSocket?.quote || btoItem?.selling_quote || "0"
            : <Skeleton baseColor="#424242" highlightColor="#202020" width="40px"/>}
        </span>
      </div>
      ) : (
        <div className="flex gap-2">
          <span className="text-tertiary">Trade Value</span>
          <span
            className={`text-[15px] ${
              activeTab == "active" && btoPnlFromSocket.quote
                ? btoPnlFromSocket.quote > btoItem.quote
                  ? "text-green-500"
                  : btoPnlFromSocket.quote == btoItem.quote
                  ? "text-yellow-500"
                  : "text-red-500"
                : btoItem?.selling_quote > btoItem?.quote
                ? "text-green-500"
                : btoItem?.selling_quote === btoItem?.quote
                ? "text-yellow-500"
                : btoItem?.selling_quote < btoItem.quote && "text-red-500"
            }`}
          >
            {/* {btoPnlFromSocket?.quote || btoItem?.selling_quote
              ? btoPnlFromSocket?.quote || btoItem?.selling_quote
              : "NA"} */}
              {(btoItem?.stc_trade_value !== undefined)
              ? "$" + btoItem?.stc_trade_value || "$0"
              : <Skeleton baseColor="#424242" highlightColor="#202020" width="40px"/>}
          </span>
        </div>
      )}
      <div className="flex gap-2">
        <span className="text-tertiary">Pnl:</span>
        <span
          className={`text-[15px] ${
            activeTab == "active" && btoPnlFromSocket.quote
              ? btoPnlFromSocket.quote > btoItem.quote
                ? "text-green-500"
                : btoPnlFromSocket.quote == btoItem.quote
                ? "text-yellow-500"
                : "text-red-500"
              : btoItem?.selling_quote > btoItem?.quote
              ? "text-green-500"
              : btoItem?.selling_quote === btoItem?.quote
              ? "text-yellow-500"
              : btoItem?.selling_quote < btoItem.quote && "text-red-500"
          }`}
        > 
          {/* {btoPnlFromSocket?.pnlPercentage ||
            btoItem?.pnlPercentage ||
            "0%"}
          {"  "}|{" "}
          {!btoPnlFromSocket?.pnl && !btoItem?.pnl
            ? "$0"
            : formatter.format(
                btoPnlFromSocket?.pnl || btoItem?.pnl
              )} */}
          {((btoPnlFromSocket?.pnlPercentage !== undefined) || (btoItem?.pnl_percent !== undefined) || (btoPnlFromSocket?.pnlPercentage !== undefined) || (btoItem?.pnl_percent !== undefined)) ? (
          <>
            {btoPnlFromSocket?.pnlPercentage || btoItem?.pnl_percent || "0%"}
            {"  |  "}
            {!btoPnlFromSocket?.pnl && !btoItem?.pnl
              ? "$0"
              : formatter.format(btoPnlFromSocket?.pnl || btoItem?.pnl)}
          </>
        ) : (
          <Skeleton baseColor="#424242" highlightColor="#202020" width="40px" />
        )}
        </span>
      </div>
    </div>
  ) : (
    <table className="w-full flex flex-col  mt-2 text-[14px] pl-2">
      <thead className="w-full flex gap-2 mb-1 text-tertiary text-center h-10  ">
        <th className="w-[50%]">
          {activeTab == "active" ? "Current" : "Last"}{" "}
        </th>
        <th className="w-[50%]">PnL % $ </th>
      </thead>
      <tbody className="text-[#929292] flex flex-col gap-3">
        {data.map((item, index) => {
          const pnlValue = socketPnlData[item.id] || {};
          // console.log(item)
          return (
            <tr
              key={index}
              className="w-full flex gap-2 text-[#929292] text-center h-16"
            >
              <td className="py-1 w-[50%] p-">
                <div
                  className={`text-[15px] ${
                    activeTab == "active" && pnlValue.quote
                      ? pnlValue.quote > item?.quote
                        ? "text-green-500"
                        : pnlValue.quote === item?.quote
                        ? "text-yellow-500"
                        : "text-red-500"
                      : item?.selling_quote > item?.quote
                      ? "text-green-500"
                      : item?.selling_quote === item?.quote
                      ? "text-yellow-500"
                      : item?.selling_quote < item?.quote && "text-red-500"
                  }`}
                >
                  {/* {"pnl" in pnlValue
                    ? ` ${pnlValue.quote}`
                    : item.lastPnlData.quote
                      ? item.lastPnlData.quote
                      : "NA"} */}
                  {/* {"pnl" in pnlValue
                    ? ` ${pnlValue?.quote}`
                    : item?.selling_quote
                      ? item?.selling_quote
                      : "-"} */}
                  {"pnl" in pnlValue
                    ? ` ${pnlValue?.quote}`
                    : item?.selling_quote
                      ? item?.selling_quote
                      : <Skeleton baseColor="#424242" highlightColor="#202020"/>}
                </div> 
              </td>
              <td className="py-1 w-[50%] p-">
                <div
                  className={`text-[15px] ${
                    activeTab == "active" && pnlValue.quote
                      ? pnlValue.quote > item.quote
                        ? "text-green-500"
                        : pnlValue.quote == item.quote
                        ? "text-yellow-500"
                        : "text-red-500"
                      : item?.selling_quote > item?.quote
                      ? "text-green-500"
                      : item?.selling_quote === item?.quote
                      ? "text-yellow-500"
                      : item?.selling_quote < item?.quote && "text-red-500"
                  }`}
                >
                  {/* {"pnl" in pnlValue
                    ? ` ${pnlValue.pnlPercentage || "0%"} | ${
                        pnlValue.pnl ? formatter.format(pnlValue.pnl) : "$0"
                      }`
                    : `  ${(item?.pnl_percent + "%") || "0%"} | ${
                        item?.pnl
                          ? formatter.format(item?.pnl)
                          : "$0"
                      }`} */}
                  {
                    "pnl" in pnlValue || (item?.pnl_percent !== undefined || item?.pnl !== undefined)
                      ? "pnl" in pnlValue
                        ? ` ${(pnlValue.pnlPercentage !== undefined) ? pnlValue.pnlPercentage : "-"} | ${
                            (pnlValue.pnl !== undefined) ? formatter.format(pnlValue.pnl) : "-"
                          }`
                        : `  ${(item?.pnl_percent !== undefined) ? (item?.pnl_percent + "%") : "-"} | ${
                            (item?.pnl !== undefined)
                              ? formatter.format(item?.pnl)
                              : "-"
                          }`
                      : <Skeleton baseColor="#424242" highlightColor="#202020"/>  
                    }
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default memo(PnlColumn);
