import React, { useEffect, useState } from "react";
import { userStore } from "../../../store/user";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Sidebar from "../../../components/Sidebar/Sidebar";

import bgLeft from "../../../assets/bgLeftRectangle.png";
import profile from "../../../assets/defaultPicture.png";
import tradier from "../../../assets/icons/dashboard/tradier.png";
import Accordion from "../../../components/Accordion/Accordion";
import { toast } from "react-toastify";
import axios from "axios";

const DashboardHome = () => {
  const navigate = useNavigate()
  const [bigNavToggle, setbigNavToggle] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tradierAuth, setTradierAuth] = useState(false);
  const [tableTimeScale, setTableTimeScale] = useState("week");
  const [btoStoreFilteredData, setBtoStoreFilteredData] = useState();
  const [btoStoreData, setBtoStoreData] = useState();

  const {
    userData,
    btoData
  } = userStore((state) => state);

  const {
    avatarUrl,
    discordServers = [],
    name,
    id: userId,
    role,
    currentBalance,
  } = userData || {};

  const location = useLocation();

  // TODO: Needs to come from server - oauth has implementation
  const state = "test-oauth";
  const scopes = "read, write, trade, market, stream";


  const handleTradierAuth = () => {
    const callbackUrl = `${window.location.href}`;
    const target = `https://api.tradier.com/v1/oauth/authorize?client_id=${process.env.REACT_APP_TRADIER_CLIENT_ID}&scope=${scopes}&state=${state}&redirect_uri=${callbackUrl}`;
    window.location.href = target;
  };

  const gentradeToken = localStorage.getItem("gentradeToken");
  const subDomainUrl = localStorage.getItem('baseURL')
  
  useEffect(() => {
    if (!gentradeToken) {
      if(subDomainUrl && gentradeToken){
        localStorage.clear();
        window.location.href = `${subDomainUrl}/signIn`
      }
      else{
        localStorage.clear();
        navigate("/signIn");
      }
    }
  }, []);

  useEffect(() => {
    const sendAuthCode = async (code) => {
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/oauth/authcode`, {
          "user_id": userId,
          "api_source": "Tradier", // TODO: Needs to become dynamic
          "code": code,
          "state": state,
          "scope": scopes
        })
        .then(res => {
          if(res.status === 200) {
            toast("Successfully authenticated", { type: "success" });
            // TODO: Navigate to home
          } else {
            toast("Authentication Failed", { type: "error" });
          }
        })
        .catch(err => console.error(err));
    };

    const query = new URLSearchParams(location.search);
    const code = query.get("code");

    if (code) {
      console.log("Code", code); // send code to backend receive access token
      setTradierAuth(true);
      sendAuthCode(code);
    }
  }, [location]);

  
  useEffect(() => {
    console.log("coming here")
    if (btoData && btoData?.active && btoData?.expired && !btoStoreData) {
      console.log("BTO", btoData)
      // console.log(btoData);
      let tempArray = btoData?.active?.map((item, index) => {
        const timestamp = new Date(item.created_at).getTime();
        return [timestamp, "active", item];
      });

      btoData?.expired?.map((item, index) => {
        const timestamp = new Date(item.created_at).getTime();
        tempArray = [...tempArray, [timestamp, "expired", item]];
      });

      btoData?.sold?.map((item, index) => {
        const timestamp = new Date(item.created_at).getTime();
        tempArray = [...tempArray, [timestamp, "sold", item]];
      });

      const currentDate = new Date().getTime();
      let lastSevenDaysArray = tempArray?.filter((entry) => {
        // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
        return currentDate - entry[0] <= 6 * 24 * 60 * 60 * 1000;
      });
      lastSevenDaysArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
      setBtoStoreFilteredData(lastSevenDaysArray);
      setBtoStoreData(tempArray);
    }
  }, [btoData]);

  
  useEffect(() => {
    switch (tableTimeScale) {
      case "year":
        if (btoStoreData) {
          let currentDate = new Date().getTime();
          let lastYearArray = btoStoreData?.filter((entry) => {
            // Check if the difference between the current date and the entry's date is less than or equal to 365 days (in milliseconds)
            return currentDate - entry[0] <= 360 * 24 * 60 * 60 * 1000;
          });
          lastYearArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
          setBtoStoreFilteredData(lastYearArray);
        }
        break;
      case "month":
        if (btoStoreData) {
          let currentDate = new Date().getTime();
          let lastMonthArray = btoStoreData?.filter((entry) => {
            // Check if the difference between the current date and the entry's date is less than or equal to 30 days (in milliseconds)
            return currentDate - entry[0] <= 29 * 24 * 60 * 60 * 1000;
          });
          lastMonthArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
          setBtoStoreFilteredData(lastMonthArray);
        }
        break;
      case "week":
        if (btoStoreData) {
          const currentDate = new Date().getTime();
          let lastSevenDaysArray = btoStoreData?.filter((entry) => {
            // Check if the difference between the current date and the entry's date is less than or equal to 7 days (in milliseconds)
            return currentDate - entry[0] <= 6 * 24 * 60 * 60 * 1000;
          });
          lastSevenDaysArray.sort((a, b) => new Date(b[0]) - new Date(a[0]));
          setBtoStoreFilteredData(lastSevenDaysArray);
        }
        break;
      case "all":
        if (btoStoreData) {
          let completeArray = btoStoreData.sort(
            (a, b) => new Date(b[0]) - new Date(a[0])
          );
          setBtoStoreFilteredData(completeArray); // Use all data for the graph
        }
        break;
    }
  }, [tableTimeScale]);

  return (
    <div className="lg:h-screen flex flex-col lg:flex-row bg bg-cover bg-center">
      <Sidebar
        bigNavToggle={bigNavToggle}
        setbigNavToggle={setbigNavToggle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        route={"dashboard"}
      />

      <div
        className={`hidden lg:block ${
          bigNavToggle ? "lg:w-[80%]" : "lg:w-[96%]"
        } bg-cover bg-no-repeat bg-left px-12 py-8 text-[15px] overflow-y-scroll scrollbar`}
        style={{ backgroundImage: `url(${bgLeft})` }}
      >
        {/* Top bar only for bigger screens  */}
        <div className="hidden lg:flex justify-end gap-4">
          <div className="flex gap-2 text-tertiary [#C4C4C4]  border border-[#646464] rounded-lg text-center px-2 py-1">
            Current balance:
            <div className="text-white">{currentBalance}</div>
          </div>
          <div className="flex">
            {role == "admin" ? (
              <Link
                to={"/admin/userInfo"}
                className="text-tertiary px-2 py-1 transition-all border border-[#646464] hover:border-tertiary text-center rounded-lg"
              >
                Admin Portal
              </Link>
            ) : (
              ""
            )}
            <img
              src={avatarUrl || profile}
              alt="profile"
              className="ml-6 border border-white rounded-full size-[34px]"
            />
          </div>
        </div>

        <div className="mt-6 overflow- scroll scrollbar">
          <div className="flex justify-between gap-4 h-[472px]">
            {tradierAuth ? (
              <div className="w-1/2 bg-[#202020] p-6 rounded-[32px] flex justify-between gap-4 h-[472px]">
                <div className="flex justify-between items-center text-[#A1A1A1]">
                  <div className="flex items-center h-[23px]">
                    <div className="">Tradier :</div>
                    <div className="ml-2 mr-1 mt-[2px] rounded-full size-2 bg-[#28A263]" />
                    <div className="text-[#28A263]">Connected</div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="">Zach Cohen</div>
                    {/* Image  */}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-4 w-1/2 h-full">
                <div className="bg-[#202020] p-6 rounded-[32px] h-1/2">
                  {/* Tradier Auth  */}
                  <div className="flex items-center h-[23px]">
                    <div className="text-[#A1A1A1]">Brokerage Account :</div>
                    <div className="ml-2 mr-1 mt-[2px] rounded-full size-2 bg-[#F24E1E]" />
                    <div className="text-[#F24E1E]">Not Connected</div>
                  </div>
                  <div className="flex flex-grow h-full justify-center items-center py-6">
                    <div className="flex items-center border border-[#6E6E6E] rounded-lg py-2 px-3">
                      <img src={tradier} className="size-6" />
                      <button
                        onClick={handleTradierAuth}
                        className="text-tertiary"
                      >
                        Sign In with Tradier
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col bg-[#202020] p-6 rounded-[32px] h-1/2">
                  {/* Default papertrail component  */}
                  <div className="flex justify-between">
                    <div className="text-[#A1A1A1] text-lg">Polygon</div>
                    <div className="">
                      <div className="text-[40px] leading-none text-[#9B9B9B] font-bold">
                        {"$" + currentBalance || "$0.00"}
                      </div>
                      <div className="text-tertiary">Current Balance</div>
                    </div>
                  </div>
                  <div className="mt-4 text-[#A1A1A1]">
                    Start trading with real-time options insights for smarter,
                    faster decisions.
                  </div>
                  <div className="flex items-end mt-auto justify-center">
                    <button className="border border-[#6E6E6E] rounded-lg py-2 px-3 text-tertiary">
                      Start Trading
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="w-1/2 bg-[#202020] p-6 rounded-[32px] gap-4 h-[472px]">
              <div className="text-[#A1A1A1]">Recent Updates</div>
            </div>
          </div>

          <div className="mt-4 bg-[#202020] p-6 rounded-[32px] h-[400px] ">
            <div className="flex justify-between items-center">
              <div className="text-lg text-[#A1A1A1]">My Trades</div>
              <select
                value={tableTimeScale}
                onChange={(e) => setTableTimeScale(e.target.value)}
                className="form-select bg-transparent border pr-4 pl-1 py-2 border-[#6E6E6E]  rounded-lg text-tertiary"
              >
                <option className="bg-[#202020]" value="week">
                  Last 7 Days
                </option>
                <option className="bg-[#202020]" value="month">
                  Last Month
                </option>
                <option className="bg-[#202020]" value="year">
                  Last Year
                </option>
                <option className="bg-[#202020]" value="all">
                  All
                </option>
              </select>
            </div>
            <div className="mt-6 overflow-y-scroll scrollbar h-[300px]">
              {btoStoreFilteredData?.map((item, index) => (
                <div>
                  <Accordion index={index} item={item} />
                </div>
              ))}
              {btoStoreFilteredData?.length === 0 && (
                <div className="flex w-full justify-center items-center h-48 text-[#7b7B7B] font-semibold text-xl">
                  No Bto's available
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
