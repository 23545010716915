import React, { useEffect, useState } from "react";
import { userStore } from "../../store/user";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// import Loader from "../loader";

const Accordion = ({ index, item }) => {
  const bto = item?.[2];
  const [openAccordion, setOpenAccordion] = useState(null);
  const [pnlStream, setPnlStream] = useState(null)
  const loading = false;

  const handleAccordionToggle = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const dateOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
    weekday: undefined,
    dayPeriod: undefined,
    separator: " ",
  };

  const dateOptions2 = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true, // Use 12-hour clock; set to false for 24-hour clock
    separator: " ", // Custom separator for the date and time
  };

  const {
    socket,
  } = userStore((state) => state);

  useEffect(() => {
    if(!socket) return;
    socket.on("pnl_stream", (message) => {
      try {
        setPnlStream(message)
      } catch (error) {
        console.error("Error parsing JSON or processing data:", error);
      }
    })
  },[socket])

  return (
    <div className="w-full mx-auto">
      {loading ? (
        // <Loader />
        <></>
      ) : (
        <div key={index}>
          <div className="bg-transparent border-b-2 border-b-[#424242] px-4 pt-2 mb- text-white">
            <div
              onClick={() => handleAccordionToggle(index)}
              className="flex justify-between pb-4 pt-1 cursor-pointer"
            >
              <div className="flex flex-col lg:flex-row lg:items-center">
                <div className="flex items-center ">
                  <div className="min-w-9">{item?.[2]?.ticker}</div>
                  <div
                    className={`ml-4 px-2 py-1 rounded-md min-w-16 text-center
                      ${
                        bto?.status === "active"
                          ? "bg-tertiary text-black font-semibold capitalize"
                          : bto?.status === "sold"
                          ? // || bto?.lastPnlData?.soldAt
                            "bg-[#EEF5CE] text-black font-semibold capitalize"
                          : "bg-[#424242] capitalize"
                      }
                    `}
                  >
                    {
                      // item?.[1] !== "active" && bto?.lastPnlData?.soldAt
                      //   ? "Sold"
                      //   : bto?.status
                      bto?.status
                    }
                  </div>
                </div>
                <div
                  className={`flex min-w-[82px] mt-2 lg:mt-0 lg:ml-4 gap-1 pb- text-[#737B7B]`}
                >
                  <div className="font-semibold lg:font-normal">PnL:</div>
                  <div className="text-white">
                    $
                    {item?.[1] === "active"
                      ? (pnlStream?.[item?.[2]?.id]?.pnl.toFixed(2) || "-")
                        ? (pnlStream?.[item?.[2]?.id]?.pnl.toFixed(2) || "-")
                        : item?.[2]?.pnl?.toFixed(2)
                      : bto?.pnl?.toFixed(2)}
                  </div>
                </div>

                <div
                  className={`flex min-w-[175px] lg:ml-4 gap-1 pb- text-[#737B7B]`}
                >
                  <div className="font-semibold lg:font-normal">
                    PnL Percentage:
                  </div>
                  <div className="text-white">
                    {item?.[1] === "active"
                      ? (pnlStream?.[item?.[2]?.id]?.pnlPercentage || "-")
                        ? (pnlStream?.[item?.[2]?.id]?.pnlPercentage || "-")
                        : item?.[2]?.pnl_percent
                      : bto?.pnl_percent}
                  </div>
                </div>

                <div className={`flex lg:ml-4 gap-1 pb- text-[#737B7B]`}>
                  <div className="font-semibold lg:font-normal">
                    {
                        bto?.status === "active"
                          ? ('Current Quote')
                          : ("Closing Quote")
                      }
                    {/* Current Quote: */}
                  </div>
                  <div className="text-white">
                    {item?.[1] === "active"
                      ? (pnlStream?.[item?.[2]?.id]?.quote || "-")
                        ? (pnlStream?.[item?.[2]?.id]?.quote || "-")
                        : bto?.selling_quote
                      : bto?.selling_quote}
                  </div>
                </div>
                {/* <div className={`ml-4 text-[#737B7B]`}>
                  {new Date(item?.[2]?.createdAt)
                    .toLocaleDateString("en-GB", dateOptions)
                    .replace("at", "-")}
                </div> */}
              </div>

              <div className="flex items-center gap-4 my-auto">
                <div className={`hidden lg:block ml-4 text-[#737B7B]`}>
                  {
                    new Date(item?.[2]?.created_at)
                      .toLocaleDateString("en-GB", dateOptions2)
                      .replace("at", "-")
                  }
                </div>

                {openAccordion === index ? (
                  <FaChevronUp size={15} />
                ) : (
                  <FaChevronDown size={15} />
                )}
              </div>
            </div>

            {openAccordion === index && (
              <div className="flex justify-between w-full gap-8 text-base flex-wrap pt-2 pb-6">
                <div className="">
                  {/* Bought Qty */}
                  <div className="text-[#868686] font-semibold">Bought qty</div>
                  <div className=" mt-2 pr-6">{bto?.qty}</div>
                </div>
                <div className="">
                  {/* Bought Quote */}
                  <div className="text-[#868686] font-semibold">
                    Bought quote
                  </div>
                  <div className=" mt-2 pr-6">{bto?.quote}</div>
                </div>
                <div className="">
                  {/* Closing qty  */}
                  <div className="text-[#868686] font-semibold">
                    Remaining qty
                  </div>
                  <div className=" mt-2 pr-6">{bto?.remainingQty || '0'}</div>
                </div>
                <div className="">
                  {/* Closing quote  */}
                  <div className="text-[#868686] font-semibold">
                    Closing quote
                  </div>
                  <div className=" mt-2 pr-6">{bto?.selling_quote || "-"}</div>
                </div>
                <div className="">
                  {/* Sold at */}
                  <div className="text-[#868686] font-semibold">Sold at</div>
                  <div className=" mt-2 pr-6">
                    {bto?.sold_at && item?.[1] !== "active"
                      ? new Date(bto?.sold_at)
                          .toLocaleDateString("en-GB", dateOptions2)
                          .replace("at", "-")
                      : "-"}
                  </div>
                </div>
                <div className="">
                  {/* Closed at */}
                  <div className="text-[#868686] font-semibold">
                    Expiry Date
                  </div>
                  <div className=" mt-2 pr-6">
                    {/* {bto?.expirationDate} */}
                    {new Date(bto?.expirationDate?.split("T")?.[0])
                          .toLocaleDateString("en-GB", dateOptions)
                          .replace("at", "-")}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Accordion;
