import axios from "axios";

export const getUserBtoData = (token) =>
  axios.get(`${process.env.REACT_APP_API_BASE_URL}/bto/getBtoData`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

export const sellFullBto = (token, id, data) =>
  axios.put(`${process.env.REACT_APP_API_BASE_URL}/bto/${id}`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

export const getProfitAndLoss = (token, value) =>
  axios.post(`${process.env.REACT_APP_API_BASE_URL}/pnl/compute`, value, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
