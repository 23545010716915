import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const TradierAuth = () => {
  const location = useLocation();

  const handleClick = () => {
    const callbackUrl = `${window.location.href}`;
    const state = 'test-oauth';
    const scopes = 'read, write, trade, market, stream';
    const target = `https://api.tradier.com/v1/oauth/authorize?client_id=${process.env.REACT_APP_TRADIER_CLIENT_ID}&scopes=${scopes}&state=${state}&redirect_uri=${callbackUrl}`;
    window.location.href = target;
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get('code');

    if(code) console.log('Code', code); // send code to backend receive access token
  }, [location]);

  return (
    <button className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg px-5 py-2.5 mt-[-22px] ml-[-32px] fixed left-2/4 top-2/4" onClick={handleClick}>Authenticate</button>
  );
}

export default TradierAuth;