export const sampleData = [
    {
      "ticker": "AAPL",
      "exp_date": "2024-03-15",
      "option_type": "Call",
      "near": "$150",
      "strike": "$155",
      "quote": "$10",
      "qty": "100",
      "otm": "+5%",
      "itm": "-2%"
    },
    {
      "ticker": "GOOG",
      "exp_date": "2024-03-15",
      "option_type": "Put",
      "near": "$2000",
      "strike": "$1950",
      "quote": "$20",
      "qty": "50",
      "otm": "+2%",
      "itm": "-4%"
    },
    {
      "ticker": "MSFT",
      "exp_date": "2024-03-15",
      "option_type": "Call",
      "near": "$300",
      "strike": "$310",
      "quote": "$15",
      "qty": "75",
      "otm": "+3%",
      "itm": "-1%"
    },
    {
      "ticker": "AMZN",
      "exp_date": "2024-03-15",
      "option_type": "Put",
      "near": "$3500",
      "strike": "$3600",
      "quote": "$30",
      "qty": "25",
      "otm": "+4%",
      "itm": "-3%"
    },
    {
      "ticker": "FB",
      "exp_date": "2024-03-15",
      "option_type": "Call",
      "near": "$250",
      "strike": "$260",
      "quote": "$12",
      "qty": "150",
      "otm": "+6%",
      "itm": "-2%"
    }
  ]
  