const tickers = [
  'SPY', 
  'AAPL', 
  'AMD', 
  'QQQ', 
  'META', 
  'SPY', 
  'AAAU', 
  'AABB', 
  'AABVF', 
  'AACAF', 
  'AACAY', 
  'AACG', 
  'AACI', 
  'AACIU', 
  'AACIW', 
  'AACS', 
  'AACT', 
  'AACT.U', 
  'AACTF', 
  'AADI' 
];

export default tickers;
