import moment from "moment";
import { getOptionContracts } from "./API/user";
import tickers from "./pages/User/popularTickers";

export const removeDuplicatesByKey = (arr, key) => {
  return arr && arr.length
    ? arr.filter(
        (elem, index, arr) =>
          arr.findIndex((obj) => obj[key] === elem[key]) === index
      )
    : [];
};

export const getExpirationDatesOfTicker = async () => {
  try {
    const responses = await Promise.allSettled(
      tickers
        .slice(0, 5)
        .map((ticker) =>
          getOptionContracts({ ticker }, process.env.REACT_APP_POLYGON_API_KEY)
        )
    );

    let newExpirationDates = {};
    responses.forEach((response, index) => {
      newExpirationDates[tickers[index]] = removeDuplicatesByKey(
        response.value.data.results,
        "expiration_date"
      )?.map((ob) => ob.expiration_date);
    });
    return newExpirationDates;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});