import React, { useEffect, useState, memo } from "react";
import { toast } from "react-toastify";
import { sellFullBto, getUserBtoData } from "../API/bto";
import { userStore } from "../store/user";
import { Button, Empty, Popconfirm, Tabs } from "antd";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { sendMessage } from "../API/discord";
import moment from "moment";
import PnlColumn from "./PnlColumn";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import RenderTable from "./RenderTable";

const BtoData = React.memo(() => {
  // const [socketLoading, setSocketLoading] = useState(true)
  // function alterSocketLoading(check) {
  //   setSocketLoading(check);
  // }
  const {
    btoData: { active = [], expired = [], sold = [] },
    // btoData,
    // setBtoData,
    // setBtoLoading,
    // userData,
    btoLoading,
  } = userStore((state) => state);
  // const token = localStorage.getItem("gentradeToken");
  // const [messageLoading, setMessageLoading] = useState({});
  // const { name } = userData || {};
  const [activeTab, setActiveTab] = useState("active");
  // const [loading, setLoading] = useState(false);
  // const [pnlData, setPnlData] = useState({});
  // const [collapsible, setCollapsible] = useState({});

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const data = localStorage.getItem("pnlData");
  //     if (data) setPnlData(JSON.parse(data));
  //   }, 15000);

  //   return () => clearInterval(interval);
  // }, []);

  // const updatedBtoMetaData = async () => {
  //   try {
  //     setBtoLoading(true);
  //     const btoResponse = await getUserBtoData(token);
  //     const { activeBtos, expiredBtos, soldBtos } = btoResponse.data || {};
  //     let expiredValues = [];
  //     if (expiredBtos?.length) {
  //       expiredValues = expiredBtos.map((d) => ({
  //         ...d,
  //         pnlText: "BTO has expired",
  //       }));
  //     }

  //     setBtoData({
  //       expired: expiredValues,
  //       active: activeBtos,
  //       sold: soldBtos?.length
  //         ? soldBtos.map((d) => ({
  //             ...d,
  //             pnlText: "BTO has been sold out",
  //           }))
  //         : [],
  //     });
  //     setBtoLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //     toast(
  //       err?.response?.data || "Please refresh to load the latest information",
  //       { type: "error" }
  //     );
  //     setBtoLoading(false);
  //   }
  // }

  // const RenderTables = React.memo(({ current }) => {
  //   const data = (activeTab==="active" ? active : sold)
  //   const [confirmOpens, setConfirmOpens] = useState({});

  //   const [qts, setQts] = useState({});

  //   const handleSellFullBto = async (item, index) => {
  //     try {
  //       setLoading(true);
  //       setConfirmOpens((prevValues) => ({
  //         ...prevValues,
  //         [index]: true,
  //       }));
  //       const newBtos = [...btoData[activeTab]];
  //       const soldBtos = [...btoData["sold"]];
  //       let {
  //         discordServerId,
  //         discordChannelId,
  //         appendText = "",
  //       } = JSON.parse(localStorage.getItem("btoMetaData") || `{}`);
  //       const pnlValue = JSON.parse(localStorage.getItem("pnlData") || `{}`);

  //       const response = await sellFullBto(token, newBtos[index].id, {
  //         serverId: discordServerId,
  //         channelId: discordChannelId,
  //         appendText,
  //         quote: pnlValue[item.id]?.quote,
  //         qty: parseInt(item?.remainingQty)
  //       });
  //       soldBtos.unshift(response.data.record);
  //       newBtos.splice(index, 1);
  //       setBtoData({ [activeTab]: newBtos, sold: soldBtos });
  //       setLoading(false);
  //       setConfirmOpens({ ...confirmOpens, [index]: false });
  //       toast(response.data.msg, { type: "success" });
  //       updatedBtoMetaData();
  //       setCollapsible((prevValue) => ({ ...prevValue, [index]: false }));
  //     } catch (err) {
  //       console.log(err);
  //       setLoading(false);
  //       toast("Something went wrong, please try again later", { type: "error" });
  //     }
  //   };

  //   const handleSendMessage = async (item, index) => {
  //     try {
  //       let {
  //         discordServerId,
  //         discordChannelId,
  //         appendText = "",
  //       } = JSON.parse(localStorage.getItem("btoMetaData") || `{}`);
  //       if (!discordServerId)
  //         return toast("Please select the discord server and channel");
  //       setMessageLoading((prevData) => ({ ...prevData, [index]: true }));
  //       const pnlValue = JSON.parse(localStorage.getItem("pnlData") || `{}`);
  //       const newBtos = [...btoData[activeTab]];
  //       const message = `STC ${qts[index] || 0} ${item.ticker} ${
  //         item.strikePrice
  //       }${item?.contractType?.slice(0, 1).toUpperCase()} ${moment(
  //         item.expirationDate,
  //         "YYYY-MM-DD"
  //       ).format("MM-DD-YYYY")} @${
  //         pnlValue[item.id]?.quote || item.quote
  //       } ${appendText}`;

  //       const response = await sendMessage(
  //         {
  //           serverId: discordServerId,
  //           channelId: discordChannelId,
  //           message,
  //           orderType: "stc",
  //           qty: parseInt(qts[index]),
  //           btoId: newBtos[index].id,
  //           quote: pnlValue[item.id]?.quote,
  //         },
  //         token
  //       );
  //       if (response?.data) {
  //         toast(response.data.msg, { type: "success" });
  //         updatedBtoMetaData();
  //         setMessageLoading((prevData) => ({ ...prevData, [index]: false }));
  //         if (response.data.newData?.status == "active") {
  //           setQts((prevData) => ({ ...prevData, [index]: "" }));
  //           newBtos[index] = response.data.newData;
  //         } else newBtos.splice(index, 1);

  //         setBtoData({
  //           ...(response.data.newData?.status == "active"
  //             ? { [activeTab]: newBtos }
  //             : { sold: [response.data.newData, ...sold], active: newBtos }),
  //         });
  //       }
  //     } catch (err) {
  //       console.log(err);
  //       setMessageLoading((prevData) => ({ ...prevData, [index]: false }));
  //       toast(
  //         err?.response?.status == 400
  //           ? "please give all the necessary fields"
  //           : err?.response?.data,
  //         { type: "error" }
  //       );
  //     }
  //   };
  //   return data?.length ? (
  //     <>
  //       <div className="hidden md:flex gap-2">
  //         <div className="w-[28%] mt-2 text-[14px] text-center">
  //           <div className="flex justify-between text-tertiary font-bold h-10">
  //             <div className="pb-2 w-[65%]">Alerted BTO</div>
  //             <div className="pb-2 w-[32%]">Date Alerted</div>
  //           </div>
  //           <div className="flex flex-col gap-3">
  //             {data.map((item, index) => {
  //               const date = moment(item.createdAt).format(
  //                 "MM-DD-YYYY HH:mm:ss"
  //               );
  //               return (
  //                 <div
  //                   key={index}
  //                   className="flex justify-between gap-2 text-[#929292] h-16 w-full"
  //                 >
  //                   {/* Alerted BTO */}
  //                   <div className="py-1 text-center w-[65%]">
  //                     <span
  //                       type="text"
  //                       className="px-2 w-full bg-[#424242] text-white py-1 rounded-md whitespace-pre-wrap"
  //                     >
  //                       {item.message ||
  //                         `BTO ${item.qty} ${item.ticker} ${
  //                           item.strikePrice
  //                         }${item.contractType[0]?.toUpperCase()} ${moment(
  //                           item.expirationDate,
  //                           "YYYY-MM-DD"
  //                         ).format("MM-DD-YYYY")} @${item.quote}`}
  //                     </span>
  //                   </div>
  //                   {/* Date */}
  //                   <div className="py-1 text-center w-[32%]">
  //                     <div className="">{date}</div>
  //                   </div>
  //                 </div>
  //               );
  //             })}
  //           </div>
  //         </div>
  //         <div className="w-[15%]">
  //           <PnlColumn activeTab={activeTab} 
  //                       socketLoading={socketLoading}
  //                       alterSocketLoading={alterSocketLoading}/>
  //         </div>

  //         <div className="w-[57%] mt-2 text-[14px] text-center">
  //           <div className="flex justify-between text-tertiary font-bold h-10">
  //             <div className="pb-2 w-[17%]">Discord Info</div>
  //             <div className="pb-2 w-[15%]">Qty left/total</div>
  //             <div className="pb-2 w-[24%]">STC Qty</div>
  //             {/* <th className="pb-2 w-[5%]">PT/SL</th> */}
  //             <div className="pb-2 w-[30%]">STC Alert</div>
  //             <div className="pb-2 w-[10%]"> </div>
  //           </div>
  //           <div className="flex flex-col gap-3">
  //             {data.map((item, index) => {
  //               const pnlValue = pnlData[item.id] || {};
  //               const message = `${name}:- STC ${qts[index] || 0} ${
  //                 item.ticker
  //               } ${item.strikePrice}${item?.contractType
  //                 ?.slice(0, 1)
  //                 .toUpperCase()} ${moment(
  //                 item.expirationDate,
  //                 "YYYY-MM-DD"
  //               ).format("MM-DD-YYYY")} @${pnlValue?.quote || item.quote}`;
  //               return (
  //                 <div
  //                   key={index}
  //                   className="flex gap-2 justify-between text-[#929292] h-16 w-full"
  //                 >
  //                   {/* Discord Info */}
  //                   <div className="py-1 text-center w-[17%] flex flex-col ">
  //                     {(item.discord_server_name && item?.discord_channel_name) ? (
  //                       <>
  //                         <span>{item?.discord_server_name}</span>{" "}
  //                         <span>{item?.discord_channel_name}</span>
  //                       </>
  //                     ) : (
  //                       "NA"
  //                     )}
  //                   </div>
  //                   {/* Qty left / total */}
  //                   <div className="py-1 pl-1 w-[15%] text-center">{`${item.remainingQty || 0}/${item.qty}`}</div>
  //                   {/* STC Qty */}
  //                   <div className="py-1 p- w-[24%] text-center">
  //                     <div className="flex justify-center gap-2">
  //                       <input
  //                         disabled={
  //                           activeTab == "expired" || !item.remainingQty || socketLoading
  //                         }
  //                         type="text"
  //                         value={qts[index]}
  //                         className="px- w-[60%] text-white bg-[#424242] py-1 rounded-md"
  //                         onChange={(e) =>
  //                           setQts((prevData) => ({
  //                             ...prevData,
  //                             [index]: e.target.value,
  //                           }))
  //                         }
  //                         onBlur={(e) => {
  //                           const value = e.target.value
  //                             ? parseInt(e.target.value)
  //                             : "";
  //                           if (!value) return;

  //                           if (value > item.remainingQty) {
  //                             toast(`You don've have ${value} qty`);
  //                             setQts((prevData) => ({
  //                               ...prevData,
  //                               [index]: "",
  //                             }));
  //                           }
  //                         }}
  //                       />
  //                       <button
  //                         className={`bg-[#424242] text-tertiary px-2 py-1 rounded-md ${
  //                           !qts[index] ||
  //                           messageLoading[index] ||
  //                           !item.remainingQty
  //                             ? "cursor-not-allowed opacity-45"
  //                             : "cursor-pointer"
  //                         }`}
  //                         title={
  //                           !item.remainingQty
  //                             ? "No quantites are left"
  //                             : !qts[index]
  //                             ? "Please add sell quantity"
  //                             : messageLoading[index]
  //                             ? "Please wait for STC process"
  //                             : ""
  //                         }
  //                         onClick={() =>
  //                           qts[index] &&
  //                           activeTab != "expired" &&
  //                           handleSendMessage(item, index)
  //                         }
  //                       >
  //                         {messageLoading[index] ? (
  //                           <div className="flex items-center text-white gap-1">
  //                             Sending{" "}
  //                             <AiOutlineLoading3Quarters className="text-[#5865F2]  text-lg animate-spin" />
  //                           </div>
  //                         ) : (
  //                           "Send"
  //                         )}
  //                       </button>
  //                     </div>
  //                   </div>
  //                   {/* STC Alert */}
  //                   <div className="py-1 p- w-[30%] text-center">
  //                     <span
  //                       type="text"
  //                       className="px-2 w-full text-white bg-[#424242] py-1 rounded-md"
  //                     >
  //                       {message}
  //                     </span>
  //                   </div>

  //                   {/* Actions  */}
  //                   {activeTab == "active" ? (
  //                     <div 
  //                     className={`pb-2 pl-2 text-center w-[10%] p-2 text-white
  //                       ${socketLoading ? "pointer-events-none" : ""}  
  //                     `}>
  //                       <Popconfirm
  //                         open={confirmOpens[index]}
  //                         title="Are you sure that you want to sell this one.?"
  //                         okButtonProps={{
  //                           type: "primary",
  //                           danger: true,
  //                           loading,
  //                           htmlType: "button",
  //                         }}
  //                         onCancel={() =>
  //                           setConfirmOpens((prevValues) => ({
  //                             ...prevValues,
  //                             [index]: false,
  //                           }))
  //                         }
  //                         onConfirm={() => socketLoading ? "" : handleSellFullBto(item, index)}
  //                       >
  //                         <div
  //                           className="bg-red-500 text-white px-1 rounded-lg py-1 cursor-pointer"
  //                           title="Click to sell the full BTO's quantity"
  //                           onClick={() =>
  //                             setConfirmOpens((prevValues) => ({
  //                               ...prevValues,
  //                               [index]: true,
  //                             }))
  //                           }
  //                         >
  //                           {confirmOpens[index] ? "Selling" : "Sell All"}
  //                         </div>
  //                       </Popconfirm>
  //                     </div>
  //                   ) : null}
  //                 </div>
  //               );
  //             })}
  //           </div>
  //         </div>
  //       </div>

  //       <div className="flex flex-col gap-4 md:hidden">
  //         {data?.map((item, index) => {
  //           const pnlValue = pnlData[item.id] || {};
  //           const message = `${name}:- STC ${qts[index] || 0} ${item.ticker} ${
  //             item.strikePrice
  //           }${item?.contractType?.slice(0, 1).toUpperCase()} ${moment(
  //             item.expirationDate,
  //             "YYYY-MM-DD"
  //           ).format("MM-DD-YYYY")} @${pnlValue?.quote || item.quote}`;
  //           return (
  //             <div
  //               className="bg-[#202020] rounded-lg px-2 py-4 mt-4 text-white"
  //               key={index}
  //             >
  //               <div className="w-full flex flex-col gap-5">
  //                 <div className="flex items-center justify-between">
  //                   <div className="flex items-center gap-2">
  //                     <span className="font-bold">{item.ticker}</span>
  //                     <PnlColumn
  //                       activeTab={activeTab}
  //                       btoItem={item}
  //                       isMobile={true}
  //                       socketLoading={socketLoading}
  //                       alterSocketLoading={alterSocketLoading}
  //                     />
  //                   </div>
  //                   <span
  //                     onClick={() =>
  //                       setCollapsible((preValues) => ({
  //                         ...preValues,
  //                         [index]: !preValues[index],
  //                       }))
  //                     }
  //                   >
  //                     {collapsible[index] ? (
  //                       <RiArrowDropUpLine className="text-3xl" />
  //                     ) : (
  //                       <RiArrowDropDownLine className="text-3xl" />
  //                     )}
  //                   </span>
  //                 </div>

  //                 {collapsible[index] ? (
  //                   <>
  //                     <div className="flex gap-1">
  //                       <span className="text-tertiary">Alerted BTO: </span>
  //                       <span className="px-2 w-full text-white bg-[#424242] py-1 rounded-md">
  //                         {item.message ||
  //                         `BTO ${item.strikePrice} ${item.ticker} ${
  //                           item.qty
  //                         }${item.contractType[0]?.toUpperCase()} ${moment(
  //                           item.expirationDate,
  //                           "YYYY-MM-DD"
  //                         ).format("MM-DD-YYYY")} @${item.quote}`}
  //                       </span>
  //                     </div>
  //                     <div className="flex gap-2">
  //                       <div className="flex gap-1">
  //                         <span className="text-tertiary">Date Alerted</span>
  //                         <span>
  //                           {moment(item.createdAt).format(
  //                             "YYYY-MM-DD HH:mm:ss"
  //                           )}
  //                         </span>
  //                       </div>
  //                       <div className="flex gap-1">
  //                         <span className="text-tertiary">Discord Info</span>
  //                         {(item?.discord_server_name && item?.discord_channel_name) ? (
  //                           <span>
  //                             {item?.discord_server_name} |{" "}
  //                             {item?.discord_channel_name}
  //                           </span>
  //                         ) : (
  //                           "NA"
  //                         )}
  //                       </div>
  //                     </div>

  //                     <div className="w-full flex gap-1">
  //                       <div className="w-[40%] flex gap-1">
  //                         <span className="text-tertiary">Qty Info:</span>
  //                         <span>
  //                           {item.remainingQty || 0}/{item.qty}
  //                         </span>
  //                       </div>
  //                       <div className="w-[60%] flex gap-1">
  //                         <span className="text-tertiary w-[40%]">STC Qty</span>
  //                         <div className="flex gap-1">
  //                           <input
  //                             disabled={
  //                               activeTab == "expired" || !item.remainingQty || socketLoading
  //                             }
  //                             type="text"
  //                             value={qts[index]}
  //                             className="px- w-[60%] text-white bg-[#424242] py-1 rounded-md"
  //                             onChange={(e) =>
  //                               setQts((prevData) => ({
  //                                 ...prevData,
  //                                 [index]: e.target.value,
  //                               }))
  //                             }
  //                             onBlur={(e) => {
  //                               const value = e.target.value
  //                                 ? parseInt(e.target.value)
  //                                 : "";
  //                               if (!value) return;

  //                               if (value > item.remainingQty) {
  //                                 toast(`You don've have ${value} qty`);
  //                                 setQts((prevData) => ({
  //                                   ...prevData,
  //                                   [index]: "",
  //                                 }));
  //                               }
  //                             }}
  //                           />
  //                           <button
  //                             className={`bg-[#424242] text-tertiary px-2 py-1 rounded-md ${
  //                               !qts[index] ||
  //                               messageLoading[index] ||
  //                               !item.remainingQty
  //                                 ? "cursor-not-allowed opacity-45"
  //                                 : "cursor-pointer"
  //                             }`}
  //                             title={
  //                               !item.remainingQty
  //                                 ? "No quantites are left"
  //                                 : !qts[index]
  //                                 ? "Please add sell quantity"
  //                                 : messageLoading[index]
  //                                 ? "Please wait for STC process"
  //                                 : ""
  //                             }
  //                             onClick={() =>
  //                               qts[index] &&
  //                               activeTab != "expired" &&
  //                               handleSendMessage(item, index)
  //                             }
  //                           >
  //                             {messageLoading[index] ? (
  //                               <div className="flex items-center text-white gap-1">
  //                                 Sending{" "}
  //                                 <AiOutlineLoading3Quarters className="text-[#5865F2]  text-lg animate-spin" />
  //                               </div>
  //                             ) : (
  //                               "Send"
  //                             )}
  //                           </button>
  //                         </div>
  //                       </div>
  //                     </div>
  //                     <div className="flex gap-1">
  //                       <span className="text-tertiary">STC Alert</span>
  //                       <span
  //                         type="text"
  //                         className="px-2 w-full text-white bg-[#424242] py-1 rounded-md"
  //                       >
  //                         {message}
  //                       </span>
  //                     </div>
  //                     <div 
  //                       className={`w-full flex justify-end
  //                         ${socketLoading ? "pointer-events-none" : ""}  
  //                       `}
  //                     >
  //                       <Popconfirm
  //                         open={confirmOpens[index]}
  //                         title="Are you sure that you want to sell this one.?"
  //                         okButtonProps={{
  //                           type: "primary",
  //                           danger: true,
  //                           loading,
  //                           htmlType: "button",
  //                         }}
  //                         onCancel={() =>
  //                           setConfirmOpens({ ...confirmOpens, [index]: false })
  //                         }
  //                         onConfirm={() => handleSellFullBto(item, index)}
  //                       >
  //                         <Button
  //                           onClick={() =>
  //                             setConfirmOpens({
  //                               ...confirmOpens,
  //                               [index]: true,
  //                             })
  //                           }
  //                           type="primary"
  //                           className="bg-red-500 text-white"
  //                         >
  //                           {confirmOpens[index] ? "Selling" : "Sell All"}
  //                         </Button>
  //                       </Popconfirm>
  //                     </div>
  //                   </>
  //                 ) : null}
  //               </div>
  //             </div>
  //           );
  //         })}
  //       </div>
  //     </>
  //   ) : (
  //     <Empty description={`No ${current} BTOs found`} />
  //   );
  // });

  // console.log(active)

  return (
    <div className="w-full flex flex-col gap-3 my-4">
      <span className="text-[22.6px] font-medium text-tertiary ">STC</span>
      {/* {active?.length || expired?.length || sold?.length ? (
        <Tabs
          onChange={(key) => setActiveTab(key)}
          defaultActiveKey="active"
          items={[
            {
              label: `Active (${active?.length})`,
              key: "active",
              children: <RenderTable data={active} current={"Active"} />,
            },
            {
              label: `Sold (${sold?.length})`,
              key: "sold",
              children: <RenderTable data={sold} current={"Sold"} />,
            },
            {
              label: `Expired (${expired?.length})`,
              key: "expired",
              children: <RenderTable data={expired} current={"Expired"} />,
            },
          ]}
        />
      ) : btoLoading ? (
        <span className="flex items-center w-full justify-center text-lg md:text-3xl text-white">
          Loading.. <AiOutlineLoading3Quarters className="animate-spin" />{" "}
        </span>
      ) : (
        !active?.length &&
        !expired?.length && <Empty description="No BTO Records Are Exist" />
      )}{" "} */}
      {(active?.length || expired?.length || sold?.length) ? (
        <div>
          <div className="flex gap-6 border-b py-2 mb-4 text-sm">
            <div 
              className={`${activeTab === "active" ? "text-tertiary underline underline-offset-[12px]" : "text-white"} font-semibold cursor-pointer`}
              onClick={() => setActiveTab('active')}
            >
              Active {"(" + active?.length + ")" || "(" + 0 + ")"}
            </div>
            <div 
              className={`${activeTab === "sold" ? "text-tertiary underline underline-offset-[12px]" : "text-white"} font-semibold cursor-pointer`}
              onClick={() => setActiveTab('sold')}
            >
              Sold {"(" + sold?.length + ")" || "(" + 0 + ")"}
            </div>
            <div 
              className={`${activeTab === "expired" ? "text-tertiary underline underline-offset-[12px]" : "text-white"} font-semibold cursor-pointer`}
              onClick={() => setActiveTab('expired')}
            >
              Expired {"(" + expired?.length + ")" || "(" + 0 + ")"}
            </div>
          </div>
          <RenderTable activeTab={activeTab} />

        </div>
      )
      :  btoLoading ? (
        <span className="flex items-center w-full justify-center text-lg md:text-3xl text-white">
          Loading.. <AiOutlineLoading3Quarters className="animate-spin" />{" "}
        </span>
      ) : (
        !active?.length &&
        !expired?.length && <Empty description="No BTO Records Are Exist" />
      )}
    </div>
  );
})

export default memo(BtoData);
