import { create } from "zustand";

export const userStore = create((set, get) => ({
  userData: null,
  loading: false,
  btoLoading: false,
  btoData: {},
  expirationDatesOfTicker: {},
  socket: null,
  wishlistData: null,
  uniqueString: "start",
  pnlData: {},
  selectedDiscordChannel: null,
  selectedOptionArray: ['SPY', 'AAPL', 'AMD', 'QQQ', 'META'],
  setUserData: (values) => {
    set({
      userData: { ...values },
    });
  },
  setLoading: (value) => set({ loading: value }),
  setBtoData: (values) =>
    set({
      btoData: { ...get().btoData, ...values },
    }),
  setExpirationDatesOfTicker: (values) =>
    set({
      expirationDatesOfTicker: { ...get().expirationDatesOfTicker, ...values },
    }),
  setBtoLoading: (value) => set({ btoLoading: value }),
  setSocket: (value) => set({ socket: value }),
  setWishListData: (value) => set({ wishlistData: value }),
  setUniqueString: (value) => set({ uniqueString: value }),
  setPnlData: (value) => set({ pnlData: value}),
  setSelectedDiscordChannel: (channel) => set({ selectedDiscordChannel: channel}),
  setSelectedOptionArray: (newArray) => set({ selectedOptionArray: newArray }),
}));
