import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import routes from "./routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userStore } from "./store/user";
import { useEffect, useState } from "react";
import { getUser } from "./API/user";
import { getUserBtoData } from "./API/bto";
import { io } from "socket.io-client";
import axios from "axios";
import tickers from "./pages/User/popularTickers";
import moment from "moment";
import { getExpirationDatesOfTicker } from "./helper";
import Error from "./pages/Error/index"
import 'react-loading-skeleton/dist/skeleton.css'

import CryptoJS from 'crypto-js';

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [paramAuth, setParamAuth] = useState()
  const {
    userData,
    loading,
    setUserData,
    setBtoLoading,
    setBtoData,
    socket,
    setSocket,
    wishlistData,
    setWishListData,
    setExpirationDatesOfTicker,
    uniqueString
  } = userStore((state) => state);
  const { id: userId,
    currentBalance } = userData || {};
  const navigate = useNavigate();
  const gentradeToken = localStorage.getItem("gentradeToken");
  const subDomainUrl = localStorage.getItem('baseURL')

  useEffect(() => {
    const authToken = searchParams.get("authToken")
    const serverId = searchParams.get("serverId")
    const subDomainUrl = searchParams.get("baseURL")
    console.log(decodeURIComponent(subDomainUrl))
    if(authToken) {
      const decodedToken = decodeURIComponent(authToken);
      const decryptedToken = CryptoJS.AES.decrypt(decodedToken, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
      const decryptedServerId = CryptoJS.AES.decrypt(serverId, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
      console.log("Decrypted Token:", decryptedToken); // Debugging
      localStorage.setItem('gentradeToken', decryptedToken);
      localStorage.setItem('baseURL', decodeURIComponent(subDomainUrl))
      localStorage.setItem('serverId', decodeURIComponent(decryptedServerId))
    }
  }, [])

  useEffect(() => {
    // if (!userId && !gentradeToken && !loading) navigate("/signIn");

    if (gentradeToken && !userId) {
      (async () => {
        try {
          const response = await getUser(gentradeToken);
          setUserData(response?.data);
          console.log(response?.data)
          localStorage.removeItem("btoMetaData");
          localStorage.removeItem("cachedDiscordChannels");
        } catch (err) {
          console.log(err);
          toast(
            err?.response?.data ||
              "Something went wrong, please try again later.",
            { type: "error" }
          );
          localStorage.clear();
          // navigate(`${baseURL}/signIn`);
          if(subDomainUrl && gentradeToken){
            localStorage.clear();
            setUserData(null);
            window.location.href = `${subDomainUrl}/signIn`
          }
          else{
            localStorage.clear();
            navigate("/signIn");
            setUserData(null);
          }
        }
      })();
    }

    if (gentradeToken) {
      //fetching btosData for the user
      (async () => {
        try {
          setBtoLoading(true);
          const btoResponse = await getUserBtoData(gentradeToken);
          const { activeBtos, expiredBtos, soldBtos } = btoResponse.data || {};
          let expiredValues = [];
          if (expiredBtos?.length) {
            expiredValues = expiredBtos.map((d) => ({
              ...d,
              pnlText: "BTO has expired",
            }));
          }

          setBtoData({
            expired: expiredValues,
            active: activeBtos,
            sold: soldBtos?.length
              ? soldBtos.map((d) => ({
                  ...d,
                  pnlText: "BTO has been sold out",
                }))
              : [],
          });
          setBtoLoading(false);
        } catch (err) {
          console.log(err);
          toast(
            err?.response?.data || "Something went wrong, please try again later",
            { type: "error" }
          );
          setBtoLoading(false);
        }
      })();
    }

    return () => {
      socket?.disconnect();
    };
  }, [userId, gentradeToken]);

  useEffect(() => {
    if (!socket && gentradeToken && userData) {
      const registerData = {
        balance: currentBalance < 1000 ? currentBalance : 1000,
      };
      const newSocket = io(process.env.REACT_APP_SOCKET_API_BASE_URL, {
        auth: { token: process.env.REACT_APP_SOCKET_API_TOKEN },
        transports: ["websocket"],
      });

      setSocket(newSocket);
      (async () => {
        try {
          const expirationDates = await getExpirationDatesOfTicker();
          setExpirationDatesOfTicker(expirationDates);
          // console.log(expirationDates);
          for (let i = 0; i < 5; i++) {
            const ticker = tickers[i];
            let current;
            let expirationDate =
              expirationDates[ticker]?.[0] ||
              moment().add("2", "days").format("YYYY-MM-DD");
            try {
              const response = await axios({
                method: "get",
                url: `https://finnhub.io/api/v1/quote?symbol=${ticker}&token=ch1arbhr01qn6tg71ijgch1arbhr01qn6tg71ik0`,
              });
              if (response.status === 200 && response?.data?.c) {
                current = response?.data?.c?.toString();
              }
            } catch (error) {
              toast(error, {
                type: "error",
              });

              current = "1000";
            }
            registerData[ticker] = [current, `${expirationDate}`, "call"];
          }

          newSocket.emit("register", {
            registerData,
            // userId: localStorage.getItem("userId"),
            userId: userData.id,
          });
          setWishListData(registerData);
        } catch (err) {
          console.log(err);
          toast("Something went wrong, please try again later", {
            type: "er",
          });
        }
      })();

      setInterval(() => {
        const state = userStore.getState();
        newSocket.emit("ping", {
          registerData: state.wishlistData,
          userId: userId || localStorage.getItem("userId"),
          changeId: state.uniqueString
        });
      }, 15000);
    }
  }, [socket, userId, userData]);

  // console.log(uniqueString)

  return (
    <div className="bg-[#111111] min-h-screen">
      {/* <Header /> */}

      <ToastContainer />
      <Routes>
        {routes.map((item, index) => (
          <Route
            key={"" + index}
            path={item.path}
            exact={item.exact}
            name={item.name}
            element={item.element}
            errorElement={<Error/>}
          />
        ))}
        {/* <Route path="*" element={<ErrorPage />} /> */}
      </Routes>

      {/* <Footer />  */}
    </div>
  );
}

export default App;
