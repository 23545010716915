import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { userStore } from "../../store/user";

import logo from "../../assets/alertsify.svg"

import { IoMenuOutline, IoStatsChart } from "react-icons/io5";
import { LuHistory } from "react-icons/lu";
import { MdArrowCircleLeft, MdArrowCircleRight } from "react-icons/md";
import { IoExitOutline } from "react-icons/io5";
import { PiRankingFill } from "react-icons/pi";

const Sidebar = ({
  bigNavToggle,
  setbigNavToggle,
  isOpen,
  setIsOpen,
  route,
}) => {
  const { setUserData, userData } = userStore((state) => state);
  const { role } = userData || {};
  const navigate = useNavigate();

  const gentradeToken = localStorage.getItem("gentradeToken");
  const subDomainUrl = localStorage.getItem('baseURL')

  const handleSignOut = () => {
    if(subDomainUrl && gentradeToken){
      localStorage.clear();
      setUserData(null);
      window.location.href = `${subDomainUrl}/signIn`
    }
    else{
      localStorage.clear();
      navigate("/signIn");
      setUserData(null);
    }
  };
  return (
    <>
      {/* Sidebar for bigger screens*/}
      {bigNavToggle ? (
        <div className="sticky top-0 left-0 w-[20%] h-screen hidden relativ lg:flex flex-col justify-between z-50 bg-[#111111] ">
          <div>
            <div
              onClick={() => {
                if(subDomainUrl && gentradeToken){
                  window.location.href = subDomainUrl
                }
                else{
                  navigate("/")
                }
              }}
              className="flex justify-center w-full text-xl text-white font-bold mt-8 cursor-pointer"
            >
              <img src={logo} className="size-16" />
            </div>
            <div className="text-tertiary w-[80%] mx-auto font-semibold mt-6">
              <Link
                to="/model"
                className={`flex gap-2 items-center text-lg leading-none ${
                  route === "model" ? "bg-tertiary text-black" : ""
                } rounded-lg px-4 py-2 mt-2`}
              >
                <IoStatsChart />
                <div>Dashboard</div>
              </Link>

              <Link
                to="/statistics"
                className={`flex gap-2 items-center text-lg leading-none ${
                  route === "stats" ? "bg-tertiary text-black" : ""
                } rounded-lg px-4 py-2 mt-2`}
              >
                <LuHistory />
                <div>History</div>
              </Link>

              <Link
                to="/leaderboard"
                className={`flex gap-2 items-center text-lg leading-none ${
                  route === "leaderboard" ? "bg-tertiary text-black" : ""
                } rounded-lg px-4 py-2 mt-2`}
              >
                <PiRankingFill />
                <div>Leaderboard</div>
              </Link>
            </div>
          </div>
          <div className="absolute w-full bottom-0 transform -translate-x-1/ text-tertiary flex mb-8">
            <div
              onClick={handleSignOut}
              className="sticky top-0 flex cursor-pointer mx-auto rounded-lg border border-[#646464] text-[14.83px] px-6 py-2"
            >
              Sign Out
            </div>
          </div>
          <MdArrowCircleLeft
            onClick={() => setbigNavToggle(false)}
            size={30}
            className="absolute text-tertiary right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2 cursor-pointer"
          />
        </div>
      ) : (
        <div className="sticky top-0 left-0 h-screen w-[4%] hidden relativ lg:flex flex-col justify-between z-[60] bg-[#111111] ">
          <div>
            <div
              onClick={() => {
                if(subDomainUrl && gentradeToken){
                  window.location.href = subDomainUrl
                }
                else{
                  navigate("/")
                }
              }}
              className="w-full flex justify-center text-sm leading-7 text-white font-bold text-center mt-8 cursor-pointer"
            >
              <img src={logo} className="size-16" />
            </div>
            <div className="text-tertiary w-[80%] mx-auto font-semibold mt-6">
              <Link
                to="/model"
                className={`flex gap-2 items-center justify-center text-lg ${
                  route === "model" ? "bg-tertiary text-black" : ""
                } rounded-lg px-1 py-2 mt-2`}
              >
                <IoStatsChart />
              </Link>

              <Link
                to="/statistics"
                className={`flex gap-2 items-center justify-center text-lg ${
                  route === "stats" ? "bg-tertiary text-black" : ""
                } rounded-lg px-1 py-2 mt-2`}
              >
                <LuHistory />
              </Link>
              <Link
                to="/leaderboard"
                className={`flex gap-2 items-center justify-center text-lg ${
                  route === "leaderboard" ? "bg-tertiary text-black" : ""
                } rounded-lg px-1 py-2 mt-2`}
              >
                <PiRankingFill />
              </Link>
            </div>
          </div>
          <div className="absolute w-ful bottom-0 transform -translate-x-1/ text-tertiary w-full flex justify-center items-center mb-8">
            <IoExitOutline
              onClick={handleSignOut}
              size={25}
              className="flex cursor-pointer mx-auto rounded-lg text-tertiary text-[14.83px px- py-"
            />
          </div>
          <MdArrowCircleRight
            onClick={() => setbigNavToggle(true)}
            size={30}
            className="absolute text-tertiary right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2 cursor-pointer"
          />
        </div>
      )}
      {/* 
        <div className="text-tertiary flex mb-8">
          <div
            onClick={handleSignOut}
            className="flex cursor-pointer mx-auto rounded-lg border border-[#646464] text-[14.83px] px-6 py-2"
          >
            Sign Out
          </div>
        </div>
      */}

      {/* Navbar (Sidebar for smaller screens)*/}
      <div className="lg:hidden w-full sticky top-0 z-50">
        <div className="bg-[#101010] px-8 flex items-center mx-auto h-16 w-full sticky top-0 z-[60]">
          <div className="relative w-full">
            <div
              onClick={() => navigate("/")}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 text-xl text-white font-bold"
            >
              <img src={logo} className="size-16" />
            </div>

            <div className="absolute right-0 top-1/2 transform -translate-y-1/2 flex text-[#AFAFAF]">
              <IoMenuOutline
                size={30}
                className=" "
                onClick={() => setIsOpen(!isOpen)}
              />
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="h-screen absolute top-0 right-0 flex flex-col justify-between w-[230px] ml-auto bg-[#101010] z-50">
            <div className="text-tertiary w-[80%] mx-auto font-semibold mt-20">
              <Link
                to="/model"
                className={`flex gap-2 items-center text-[12.36px] ${
                  route === "model" ? "bg-tertiary text-black" : ""
                } rounded-lg px-4 py-2`}
              >
                <IoStatsChart />
                <div>Dashboard</div>
              </Link>
              <Link
                to="/statistics"
                className={`flex gap-2 items-center text-[12.36px] ${
                  route === "stats" ? "bg-tertiary text-black" : ""
                } rounded-lg px-4 py-2 mt-2`}
              >
                <LuHistory />
                <div>History</div>
              </Link>
              <Link
                to="/statistics"
                className={`flex gap-2 items-center text-[12.36px] ${
                  route === "leaderboard" ? "bg-tertiary text-black" : ""
                } rounded-lg px-4 py-2 mt-2`}
              >
                <PiRankingFill />
                <div>Leaderboard</div>
              </Link>
              {role == "admin" ? (
                <Link
                  to="/admin/userInfo"
                  className="flex gap-2 items-center text-[12.36px] text-tertiary border border-[#646464] rounded-lg px-4 py-2 mt-2"
                >
                  <div>Admin Panel</div>
                </Link>
              ) : (
                ""
              )}
            </div>
            <div>
              <div className="absolute w-full bottom-0 transform -translate-x-1/ text-tertiary flex mb-8">
                <div
                  onClick={handleSignOut}
                  className="sticky top-0 flex cursor-pointer mx-auto rounded-lg border border-[#646464] text-[14.83px] px-6 py-2"
                >
                  Sign Out
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
