import React, { useEffect, useState } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import bgTop from "../../assets/bgTopRectangle.png";
import heroBg from "../../assets/Images/Home/heroBg.png";
import leftDonut from "../../assets/leftDonut.png";
import phoneFlowerBottomLeft from "../../assets/phoneFlowerBottomLeft.png";
import phoneFlowerTopRight from "../../assets/phoneFlowerTopRight.png";
import modelBg from "../../assets/Images/Home/modelShowBg.png";
import modelShow from "../../assets/Images/Home/modelShow.png";
import userHistoryShow from "../../assets/Images/Home/userHistoryShow.png";
import list1 from "../../assets/Images/Home/list1.png";
import gold from "../../assets/Images/Medals/gold.png";
import bronze from "../../assets/Images/Medals/bronze.png";
import silver from "../../assets/Images/Medals/silver.png";
import honour from "../../assets/Images/Medals/honour.png";
import Loader from "../../components/Loader";

import { LuClock3 } from "react-icons/lu";
import { GoGraph } from "react-icons/go";
import { MdDashboard } from "react-icons/md";
import { FaDiscord } from "react-icons/fa";
import { BiDetail } from "react-icons/bi";
import { HiOutlineCurrencyDollar } from "react-icons/hi";

import delay from "../../assets/icons/home/expired.png";
import accuracy from "../../assets/icons/home/accuracy.png";
import contract from "../../assets/icons/home/agreement.png";
import community from "../../assets/icons/home/community.png";
import arrowRight from "../../assets/icons/home/right.png";

const Home = () => {
  const navigate = useNavigate();
  const gentradeToken = localStorage.getItem("gentradeToken");
  const subDomainUrl = localStorage.getItem('baseURL')

  const [leaderboardCategory, setLeaderBoardCategory] = useState("monthly");
  const [monthLeaderboard, setMonthLeaderBoard] = useState([]);
  const [dayLeaderboard, setDayLeaderBoard] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if(subDomainUrl && gentradeToken){
      navigate("/model")
    }
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/pnl/leaderboard?serverId=${process.env.REACT_APP_DISCORD_SERVER_ID}&useCache=false`
        );
        // setLeaderBoardData(response.data);
        const responseArray = response.data;
        const responseMonthArray = [...responseArray]
        const responseDayArray = [...responseArray]
        
        responseMonthArray.sort((a, b) => {  
          return (
            Number(b?.month?.pnlPercentage?.split("%")?.[0]) -
            Number(a?.month?.pnlPercentage?.split("%")?.[0])
          );
        });
        setMonthLeaderBoard(responseMonthArray);

        responseDayArray.sort((a, b) => {
          return (
            Number(b?.dayPnl?.pnlPercentage?.split("%")?.[0]) -
            Number(a?.dayPnl?.pnlPercentage?.split("%")?.[0])
          );
        });
        setDayLeaderBoard(responseDayArray);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div className="text-black pb-24">
      <div
        className="h-screen flex flex-col sm:justify-center pt-[35%] sm:pt-0 sm:-mt-2 bg-cover bg-center z-40"
        style={{ backgroundImage: `url(${heroBg})` }}
      >
        <div className="text-heading text-[40px] leading-tight min-[470px]:text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-medium text-center lg:leading-[110px] px-2 z-40">
          Alertsify – Compete,
          <br /> Conquer, and Cash-In!
        </div>
        <div className="text-body text-center text-[15.27px] min-[470px]:text-[16.04px] sm:text-xl font-medium mt-12 sm:mt-5 mb-12 px-8 sm:w-[450px] md:w-[528px] lg:w-[600px] mx-auto z-40">
          Maximize Your Trading Potential: Precision Automation and
          Real-Time Analytics.
        </div>
        <div className="flex flex-col sm:flex-row w-[164px] pt-16 sm:pt-0 mx-auto sm:mx-0 sm:items-start sm:w-full gap-4 justify-center z-40">
          {gentradeToken ? (
            <>
              <button
                onClick={() => navigate("/model")}
                className="flex gap-2 justify-center items-center w-[170px] bg-tertiary text-black text-sm lg:text-base rounded-full sm:px-6 py-1 px-4 sm:py-2 font-semibold"
              >
                <MdDashboard size={25} className="" />
                Dashboard
              </button>
              <button 
                onClick={() => window.open("https://discord.gg/7fs5xMSyB4")}
                className="w-[170px] border-2 border-tertiary text-tertiary text-sm lg:text-base rounded-full sm:px-6 py-1 px-4 sm:py-2 font-semibold"
              >
                Visit our discord
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => navigate("/signIn")}
                className="flex gap-2 justify-center items-center w-[170px] bg-tertiary text-black text-sm lg:text-base rounded-full sm:px-6 py-1 px-4 sm:py-2 font-semibold"
              >
                <FaDiscord size={25} className="" />
                Sign-In
              </button>
              <button 
                onClick={() =>  window.open("https://whop.com/tradeproelite/?%3Fpass=prod_oP4t99lFAfErW")}
                className="w-[170px] border-2 border-tertiary text-tertiary text-sm lg:text-base rounded-full sm:px-6 py-1 px-4 sm:py-2 font-semibold"
              >
                Join our discord
              </button>
            </>
          )}
          {/* <button className="border-2 border-tertiary text-tertiary text-sm lg:text-base rounded-full sm:px-6 py-1 px-4 sm:py-2 font-semibold">
            Learn More
          </button> */}
        </div>
      </div>

      <div className="flex flex-col items-center mx-auto mt-12 bg-[#111111] px-8 text-[16.04px] sm:text-[18.335px] lg:text-[22px] z-40">
        <div className="sm:w-[70%] z-40">
          <div className="text-[42px] sm:text-5xl lg:text-6xl text-heading font-medium text-center">
            How Alertsify Works?
          </div>
          <div className="text-body text-[16.04px] sm:text-[18.335px] lg:text-[22px] mt-8">
            Our software offers a real-time options alert system that
            dramatically improves the efficacy and efficiency of trade execution
            for members of trading communities. By leveraging an advanced
            options algorithm, our bot ensures instantaneous dissemination of
            Buy-To-Open (BTO) and Sell-To-Close (STC) alerts directly to
            Discord.
            <br />
            <br />
            This software resolves critical issues prevalent in the trading
            community, such as delayed alerts, incorrect pricing, and incomplete
            contract information, thus fostering a transparent, reliable, and
            authentic trading environment.
          </div>
        </div>
        <div className="flex flex-col max-[950px]:items-center 2md:flex-row 2md:justify-center 2md:flex-wrap xl:flex-nowrap gap-4 mt-16 w-full sm:w-[85%]">
          <div className="flex flex-col px-4 sm:px-10 text-center gap-8 items-center bg-[#202020] rounded-3xl w-72 sm:w-96 py-8 sm:py-16">
            <LuClock3 size={60} className="text-tertiary" />
            <div className="text-tertiary text-xl 2md:h-[40px]">
              Instant Alerts
            </div>
            <div className="text-white">
              Experience real-time alerts for immediate trading action.
            </div>
          </div>

          <div className="flex flex-col px-4 sm:px-10 text-center gap-8 items-center bg-[#202020] rounded-3xl w-72 sm:w-96 py-8 sm:py-16">
            <HiOutlineCurrencyDollar size={60} className="text-tertiary" />
            <div className="text-tertiary text-xl 2md:h-[40px]">
              Precision in Pricing
            </div>
            <div className="text-white ">
              Get accurate pricing to minimize errors and maximize gains.
            </div>
          </div>

          <div className="flex flex-col px-4 sm:px-10 text-center gap-8 items-center bg-[#202020] rounded-3xl w-72 sm:w-96 py-8 sm:py-16">
            <BiDetail size={60} className="text-tertiary" />
            <div className="text-tertiary text-xl 2md:h-[40px]">
              Detailed Contract Information
            </div>
            <div className="text-white">
              Receive comprehensive contract details for informed decisions.
            </div>
          </div>

          <div className="flex flex-col px-4 sm:px-10 text-center gap-8 items-center bg-[#202020] rounded-3xl w-72 sm:w-96 py-8 sm:py-16">
            <GoGraph size={60} className="text-tertiary" />
            <div className="text-tertiary text-xl 2md:h-[40px]">
              Seamless Integration
            </div>
            <div className="text-white">
              Effortlessly incorporate Alertsify into your Day
              Trading community.
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center sm:w-[70%} mt-8">
          <img
            src={list1}
            className="h-[325px] min-[450px]:h-[400px] sm:h-[500px]"
          />
          <div className="text-center text-lg text-tertiary mt-8 w-[60%]">
            Learn, practice, and compete with traders globally for cash prizes &
            analyst position at TradeProElite
          </div>
        </div>
      </div>

      <div className="sm:w-[70%] px-4 mx-auto z-40 mt-16">
        <div className="text-[42px] sm:text-5xl lg:text-6xl text-heading font-medium text-center">
          Elevate Your Community to New Heights
        </div>
      </div>
      <div className="flex flex-col items-center gap-4 sm:gap-10 mt-16 w-full sm:w-[70%] px-4 mx-auto">
        <div className="w-full flex justify-start">
          <div className="flex px-4 sm:px-10 text-center gap-8 items-center bg-[#202020] rounded-3xl w-full md:w-[65%] py-8 sm:py-12">
            {/* <LuTimerOff size={80} className="text-tertiary" /> */}
            <img src={delay} className="size-[80px]" />
            <div className="mx-aut ml-2 text-left">
              <div className="text-tertiary text-xl 2md:h-[40px]">
                Eliminate Delays
              </div>
              <div className="text-white">
                Our bot ensures you receive instantaneous trade alerts.
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-end">
          <div className="flex flex-co px-4 sm:px-10 text-center gap-8 items-center bg-[#202020] rounded-3xl w-full md:w-[65%] py-8 sm:py-12">
            {/* <GiBullseye size={90} className="text-tertiary" /> */}
            <img src={accuracy} className="size-[80px]" />
            <div className="mx-aut ml-2 text-left">
              <div className="text-tertiary text-xl 2md:h-[40px]">
                Accuracy in Pricing
              </div>
              <div className="text-white ">
                Depend on precise pricing for every trade alert.
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-start">
          <div className="flex flex-co px-4 sm:px-10 text-center gap-8 items-center bg-[#202020] rounded-3xl w-full md:w-[65%] py-8 sm:py-12">
            {/* <IoDocumentsOutline size={80} className="text-tertiary" /> */}
            <img src={contract} className="size-[80px]" />
            <div className="mx-aut ml-2 text-left">
              <div className="text-tertiary text-xl 2md:h-[40px]">
                Complete Contract Details
              </div>
              <div className="text-white">
                Access full contract information for every alert.
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-end">
          <div className="flex flex-co px-4 sm:px-10 text-center gap-8 items-center bg-[#202020] rounded-3xl w-full md:w-[65%] py-8 sm:py-12">
            {/* <FaPeopleGroup size={80} className="text-tertiary" /> */}
            <img src={community} className="size-[80px]" />
            <div className="mx-aut ml-2 text-left">
              <div className="text-tertiary text-xl 2md:h-[40px]">
                Community Building
              </div>
              <div className="text-white">
                Users can build their trading stats and compete for an analyst
                contract at TradeProElite.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col gap-5 justify-center items-center mx-auto mt-12 z-20">
        <div
          className="min-h-scre w-full bg-contain z-40 opacity-"
          style={{ backgroundImage: `url(${modelBg})` }}
        >
          <div className="pt-12 text-center text-tertiary text-2xl font-semibold z-40">
            Empower Your Trades, Elevate Your Profits with Alertsify!
          </div>
          <img
            src={modelShow}
            className="mx-auto mt-12 size-[85%] sm:size-[65%] z-40"
          />
        </div>
        <div className="flex justify-center gap-8 px-8 mt-12 w-full sm:w-[70%] mx-auto xl:h-[400px z-40">
          <div className="w-[50% w-full">
            <div className="rounded-3xl h-[670px] bg-[#202020] p- overflow-hidden">
              <div className="text-[#999999 text-black bg-tertiary text-2xl pt-4 px-4 font-bold text-center">
                Profit/Loss Leaderboard
              </div>
              <div className="flex gap-2 justify-center bg-tertiary py-4 px-4 font-semibold">
                <div
                  className={`w-[80px] text-center py-[2px] ${
                    leaderboardCategory === "monthly"
                      ? "bg-black text-tertiary"
                      : "border-2 border-black text-black"
                  } rounded-full cursor-pointer`}
                  onClick={() => setLeaderBoardCategory("monthly")}
                >
                  Monthly
                </div>
                <div
                  className={`w-[80px] text-center py-[2px] ${
                    leaderboardCategory === "daily"
                      ? "bg-black text-tertiary"
                      : "border-2 border-black text-black"
                  } rounded-full cursor-pointer`}
                  onClick={() => setLeaderBoardCategory("daily")}
                >
                  Daily
                </div>
              </div>
              {isLoading ? (
                <div className="text-4xl text-white pt-8 flex items-center justify-center">
                  <Loader />
                </div>
              ) : (
                <div className="mt-4 px-4">
                  <div className="flex font-semibold justify-between mb-2 py-2 px-2 bg-[#111111] rounded-full text-[#C4C4C4  text-tertiary border- border-b-[#939393]">
                    <div className="w-[20%] md:w-[12%] pl-4">#</div>
                    <div className="w-[40%] md:w-[28%]">Name</div>
                    <div className="hidden md:block w-[15%]">Age</div>
                    <div className="w-[25%] md:w-[15%]">PnL</div>
                    <div className="hidden md:block w-[15%]">Percentage</div>
                  </div>
                  <div className="h-[450px] min-[380px]:h-[500px] overflow-y-auto scrollbar">
                    {leaderboardCategory === "monthly"
                      ? monthLeaderboard.slice(0, 12).map((lbd, i) => (
                          <div
                            className="flex justify-between items-center py-1 px-2 text-[#C4C4C4]"
                            key={i}
                          >
                            <div className="w-[20%] md:w-[12%]">
                              <img
                                src={
                                  i === 0
                                    ? gold
                                    : i === 1
                                    ? silver
                                    : i === 2
                                    ? bronze
                                    : honour
                                }
                                className="size-12"
                                alt="medal"
                              />
                            </div>
                            <div className="w-[40%] md:w-[28%] pb-1">
                              {lbd.user.name}
                            </div>
                            <div className="hidden md:block w-[15%] pb-1">
                              {lbd.user.age} days
                            </div>
                            <div className="w-[25%] md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                              {lbd?.month?.pnl}
                            </div>
                            <div className="hidden md:block w-[15%] pb-1 text-tertiar text-white font-semibold">
                              {lbd?.month?.pnlPercentage}
                            </div>
                          </div>
                        ))
                      : dayLeaderboard.slice(0, 12).map((lbd, i) => (
                          <div
                            className="flex justify-between items-center py-1 px-2 text-[#C4C4C4]"
                            key={i}
                          >
                            <div className="w-[20%] md:w-[12%]">
                              <img
                                src={
                                  i === 0
                                    ? gold
                                    : i === 1
                                    ? silver
                                    : i === 2
                                    ? bronze
                                    : honour
                                }
                                className="size-12"
                                alt="medal"
                              />
                            </div>
                            <div className="w-[40%] md:w-[28%] pb-1">
                              {lbd.user.name}
                            </div>
                            <div className="hidden md:block w-[15%] pb-1">
                              {lbd.user.age} days
                            </div>
                            <div className="w-[25%] md:w-[15%] pb-1 text-tertiar text-white font-semibold">
                              {lbd?.dayPnl?.pnl}
                            </div>
                            <div className="hidden md:block w-[15%] pb-1 text-tertiar text-white font-semibold">
                              {lbd?.dayPnl?.pnlPercentage}
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col-reverse gap-12 items-center px-8 sm:px-8 sm:w-[70%] mt-20 sm:mt-36 mx-auto text-body text-[16.04px] sm:text-[18.335px] lg:text-[22px]">
        <img
          src={userHistoryShow}
          className="mx-auto mt- 12 size-[75%] sm:size-[55%] z-40"
        />
      </div>

      <div className="px-8 sm:w-[70%] mx-auto mt-36">
        <div className="text-[42px] leading-[50px] sm:text-5xl lg:text-6xl text-heading sm:text-center 2md:text-left font-medium 2md:w-[70%">
          Compete and Connect
        </div>
        <div className=" text-body text-[16.04px] sm:text-[18.335px] lg:text-[22px] mt-8 lg:mt-12 w-full 2md:w-[75%">
          <div className="text-tertiary flex items-center gap-2 font-semibold underline mt-4">
            {/* <PiArrowFatLineRightBold className="mt-1"/> */}
            <img src={arrowRight} className="size-6 mt-2" />
            Build Your Stats:
          </div>
          <div className="ml-7">
            Every user has the opportunity to build their performance stats on
            Alertsify.
          </div>
          <div className="text-tertiary flex items-center gap-2 font-semibold underline mt-4">
            {/* <PiArrowFatLineRightBold className="mt-1"/> */}
            <img src={arrowRight} className="size-6 mt-2" />
            Compete for Recognition:
          </div>{" "}
          <div className="ml-7">
            Top performers can compete for a position as an analyst at
            TradeProElite.
          </div>
          <div className="text-tertiary flex items-center gap-2 font-semibold underline mt-4">
            {/* <PiArrowFatLineRightBold className="mt-1"/> */}
            <img src={arrowRight} className="size-6 mt-2" />
            Social Integration:
          </div>{" "}
          <div className="ml-7">
            Link your Twitter or trading group to your Alertsify profile for
            wider community engagement.
          </div>
        </div>
      </div>

      {/* <div className="flex flex-col items-center mx-auto mt-36 px-4 sm:w-[70%]">
        <div className="text-[42px] leading-tight sm:text-5xl tracking-wide lg:text-6xl text-heading font-medium text-center w-full">
          Pricing Plans Tailored for <br /> Your Needs
        </div>
        <div className="text-body text-[13.75px text-[16.04px] sm:text-[18.335px] lg:text-[22px] mt-8 w-[300px] md:w-[700px] text-center">
          Discover flexible pricing options that cater to your unique needs.
        </div>
        <div className="grid grid-cols-1 2md:grid-cols-3 2md:w-full justify-between gap-4 mt-16 ">
          <div className="flex flex-col bg-[#202020] rounded-3xl px-5 lg:px-10 py-[44px] 2md:py-5 lg:py-[30px] xl:py-[44px]">
            <BsFillLightningFill size={24} className="text-tertiary" />
            <div className="text-body text-lg lg:text-xl xl:text-2xl mt-2 lg:mt-4 font-semibold">
              Basic
            </div>
            <div className="text-heading flex items-end my-4 lg:my-6 font-semibold">
              <div className="text-3xl lg:text-5xl xl:text-6xl">$20</div>
              <div className="text-lg xl:text-xl">/mo</div>
            </div>
            <div className="text-white text-sm lg:text-base font-light pl-2 lg:pl-5 h-[140px] xl:h-[120px]">
              <ul className="list-disc">
                <li className="mb-1">Essential features to get you started</li>
                <li>Monthly updates and improvements</li>
              </ul>
            </div>
            <button className="text-tertiary lg:text-lg rounded-full border border-tertiary w-full lg:w-[75%] text-nowrap py-1 mx-auto mt-6 lg:mt-10">
              Choose Plan
            </button>
          </div>

          <div className="flex flex-col bg-[#202020] rounded-3xl px-5 lg:px-10 py-[44px] 2md:py-5 lg:py-[30px] xl:py-[44px]">
            <FaCrown size={24} className="text-tertiary" />
            <div className="text-body text-lg lg:text-xl xl:text-2xl mt-2 lg:mt-4 font-semibold">
              Basic
            </div>
            <div className="text-heading flex items-end my-4 lg:my-6 font-semibold">
              <div className="text-3xl lg:text-5xl xl:text-6xl">$30</div>
              <div className="text-lg xl:text-xl">/mo</div>
            </div>
            <div className="text-white text-sm lg:text-base font-light pl-2 lg:pl-5 h-[140px] xl:h-[120px]">
              <ul className="list-disc">
                <li className="mb-1">All in basic</li>
                <li className="mb-1">Priority Support</li>
                <li>Monthly updates and improvements</li>
              </ul>
            </div>
            <button className="text-tertiary lg:text-lg rounded-full border border-tertiary w-full lg:w-[75%] text-nowrap py-1 mx-auto mt-6 lg:mt-10">
              Choose Plan
            </button>
          </div>

          <div className="flex flex-col bg-[#202020] rounded-3xl px-5 lg:px-10 py-[44px] 2md:py-5 lg:py-[30px] xl:py-[44px]">
            <MdOutlineBarChart size={24} className="text-tertiary" />
            <div className="text-body text-lg lg:text-xl xl:text-2xl mt-2 lg:mt-4 font-semibold">
              Basic
            </div>
            <div className="text-heading flex items-end my-4 lg:my-6 font-semibold">
              <div className="text-3xl lg:text-5xl xl:text-6xl">$40</div>
              <div className="text-lg xl:text-xl">/mo</div>
            </div>
            <div className="text-white text-sm lg:text-base font-light pl-2 lg:pl-5 h-[140px] xl:h-[120px]">
              <ul className="list-disc">
                <li className="mb-1">Tailored for large enterprises</li>
                <li className="mb-1">Advance features and scalability</li>
                <li>Compatible with custom third-party platforms </li>
              </ul>
            </div>
            <button className="text-tertiary lg:text-lg rounded-full border border-tertiary w-full lg:w-[75%] px-2 text-nowrap py-1 mx-auto mt-6 lg:mt-10">
              Choose Plan
            </button>
          </div>
        </div>
      </div> */}

      {/* <div className="w-[85%] sm:w-[70%] flex flex-col 2md:flex-row justify-between items-center mx-auto mt-24 bg-tertiary 2md:h-[280px] lg:h-[290px] xl:h-[320px] px-8 sm:px-10 py-12 2md:py-0 rounded-3xl z-10 bg-opacity-100">
        <div className="text-4xl sm:text-5xl xl:text-6xl font-bold lg:leading-[58px] xl:leading-[70px] text-[#202020] 2md:w-[30%]">
          Book a free demo with us.
        </div>
        <div className="text-black flex flex-col 2md:justify-between h-full mt-12 2md:mt-6 2md:py-12 text-[18.335px] lg:text-[22px] w-full 2md:w-[60%] ">
          <div className="">
            Have questions? Our team is here to provide answers and insights.
          </div>
          <div className="flex mt-6 2md:mt-0 2md:justify-between">
            <input
              type="text"
              placeholder="Enter you email"
              className="focus:outline-none bg-transparent border-b-[3px] border-b-black w-[88%] 2md:px-1 placeholder:text-black placeholder:font-light"
            />
            <RiExternalLinkLine className="w-[12%] 2md:-mb-1 text-[30px] 2md:text-[50px]" />
          </div>
        </div>
      </div> */}

      <img
        src={leftDonut}
        alt="donut"
        className="absolute hidden md:block left-0 top-[250%] h-[120%] z-0"
      />

      {/* <img
        src={rightSphere}
        alt="sphere"
        className="absolute hidden md:block right-0 top-[320%] h-[140%] z-0"
      />

      <img
        src={rightDonut}
        alt="donut"
        className="absolute md:hidden right-0 top-[142%] h-[80%] z-0"
      />

      <img
        src={leftSphere}
        alt="sphere"
        className="absolute md:hidden left-0 top-[300%] h-[130%] z-0"
      />

      {/* Mobile Flowers */}
      <img
        src={phoneFlowerTopRight}
        alt="flower"
        fill
        className="
            lg:hidden
            absolute w-[50%] sm:w-auto
            top-0 right-0 z-0
          "
      />
      <img
        src={phoneFlowerBottomLeft}
        alt="flower"
        fill
        className="
            lg:hidden
            absolute z-0 w-[50%] sm:w-auto
            bottom-0 left-0 translate-y-1/2 
          "
      />
    </div>
  );
};

export default Home;
