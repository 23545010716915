import React, { useEffect, useState } from "react";

import bgLeft from "../../assets/bgLeftRectangle.png";
import bgPhoneTop from "../../assets/bgPhoneTopRectangle.png";
import profile from "../../assets/defaultPicture.png";
import phoneProfile from "../../assets/defaultPhonePicture.png";

import { IoMenuOutline } from "react-icons/io5";
import { IoStatsChart } from "react-icons/io5";
import { LuHistory } from "react-icons/lu";

import { MdPerson } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";

import { RiEditLine } from "react-icons/ri";

const Profile = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      // Prevent scrolling of the body when the navbar is open
      document.body.style.overflow = "hidden";
    } else {
      // Restore scrolling when the navbar is closed
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <div
      className="h-screen flex flex-col lg:flex-row bg bg-cover bg-center"
      // style={{backgroundImage: `url(${bgLeft})`}}
    >
      {/* Sidebar for bigger screens*/}
      <div className="w-[20%] hidden lg:flex flex-col justify-between z-50 bg-[#111111] ">
        <div>
          <div className="w-full text-2xl text-white font-bold text-center mt-8">
            LOGO
          </div>
          <div className="text-tertiary w-[80%] mx-auto font-semibold mt-6">
            <div className="flex gap-2 items-center text-lg bg-tertiary text-black rounded-lg px-4 py-2">
              <IoStatsChart />
              <div>Trade Model</div>
            </div>
            <div className="flex gap-2 items-center text-lg rounded-lg px-4 py-2 mt-2">
              <LuHistory />
              <div>History</div>
            </div>
          </div>
        </div>
        <div className="text-tertiary flex mb-8">
          <div className="flex mx-auto rounded-lg border border-[#646464] text-[14.83px] px-6 py-2">
            Sign Out
          </div>
        </div>
      </div>

      {/* Navbar (Sidebar for smaller screens)*/}
      <div className="lg:hidden w-full sticky top-0">
        <div className="bg-[#101010] px-8 flex items-center mx-auto h-16 w-full sticky top-0 z-50">
          <div className="relative w-full">
            <div className="absolute left-0 top-1/2 transform -translate-y-1/2 text-xl text-white font-bold">
              LOGO
            </div>

            <div className="absolute right-0 top-1/2 transform -translate-y-1/2 flex text-[#AFAFAF]">
              <IoMenuOutline
                size={30}
                className=" "
                onClick={() => setIsOpen(!isOpen)}
              />
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="h-screen absolute top-0 right-0 w-[230px] ml-auto bg-[#101010] z-40">
            <div className="text-tertiary w-[80%] mx-auto font-semibold mt-20">
              <div className="flex gap-2 items-center text-[12.36px] bg-tertiary text-black rounded-lg px-4 py-2">
                <IoStatsChart />
                <div>Trade Model</div>
              </div>
              <div className="flex gap-2 items-center text-[12.36px] rounded-lg px-4 py-2 mt-2">
                <LuHistory />
                <div>History</div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Body for larger screens  */}
      <div
        className="hidden lg:block lg:w-[80%] bg-cover bg-no-repeat bg-left px-20 py-8 text-[16px]"
        style={{ backgroundImage: `url(${bgLeft})` }}
      >
        <div className="flex justify-end">
          <button className="text-tertiary px-2 py-1 border border-[#646464] text-center rounded-lg">
            Admin Portal
          </button>
          <img src={profile} alt="profile" className="ml-6 size-[34px]" />
        </div>

        <div>
          <div className="text-[42px] text-[#C5C5C5] font-semibold mt-4">
            My Profile
          </div>

          <div
            className="
            flex bg-[#202020] rounded-3xl mt-8 py-10 
            px-12
            w-full
            min-[1355px]:w-[88%]
            min-[1571px]:w-[80%]
            "
          >
            <div className="flex w-[166px]">
              <img src={phoneProfile} alt="profile" className="size-[130px] " />
            </div>
            <div className="w-full pl-4 flex flex-col gap-3 text-[#9B9B9B] ">
              <div className="flex items-center gap-4 px-4 border-2 border-[#646464] rounded-xl w-full h-[45px] py-4">
                <MdPerson size={25} />
                <input
                  className="bg-transparent outline-none placeholder:text-[#9b9b9b]"
                  placeholder="Name"
                />
              </div>
              <div className="flex items-center gap-4 px-4 border-2 border-[#646464] rounded-xl w-full h-[45px] py-4">
                <MdOutlineMail size={25} />
                <input
                  className="bg-transparent outline-none placeholder:text-[#9b9b9b]"
                  placeholder="Email"
                />
              </div>
              <div className="flex items-center gap-4 mt-4">
                <div className="font-medium text-white">User Since :</div>
                <div className="bg-[#2D2D2D] px-3 py-1 rounded-lg">
                  07/05/2023
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div className="font-medium text-white">
                  Current subscription till :
                </div>
                <div className="bg-[#2D2D2D] px-3 py-1 rounded-lg">
                  07/05/2024
                </div>
              </div>
            </div>
          </div>

          <div
            className="flex justify-center mt-8 w-full
            min-[1355px]:w-[88%]
            min-[1571px]:w-[80%]"
          >
            <button className="flex items-center gap-2 text-tertiary border border-tertiary rounded-lg px-4 py-1">
              <RiEditLine size={22} />
              <div>Edit Profile</div>
            </button>
          </div>
        </div>
      </div>

      {/* Body for smaller screens  */}
      <div
        className="lg:hidden h-screen w-full bg-cover bg-center bg-no-repeat px-4 flex flex-col justify-center"
        style={{ backgroundImage: `url(${bgPhoneTop})` }}
        onClick={() => setIsOpen(false)}
      >
        <div className="text-[38px] text-[#C5C5C5]  font-semibold -mt-8">
          My Profile
        </div>

        <div
          className="
            flex flex-col bg-[#202020] rounded-3xl mt-8 py-10 text-[14.5px]
            px-4
            sm:px-12
            w-full
            "
        >
          <div className="flex mx-auto w-[130px]">
            <img src={phoneProfile} alt="profile" className="size-[130px] " />
          </div>
          <div className="w-full flex flex-col gap-3 text-[#9B9B9B] mt-10">
            <div className="flex items-center gap-4 px-4 border-2 border-[#646464] rounded-xl w-full h-[45px] py-4">
              <MdPerson size={25} />
              <input
                className="bg-transparent outline-none placeholder:text-[#9b9b9b]"
                placeholder="Name"
              />
            </div>
            <div className="flex items-center gap-4 px-4 border-2 border-[#646464] rounded-xl w-full h-[45px] py-4">
              <MdOutlineMail size={25} />
              <input
                className="bg-transparent outline-none placeholder:text-[#9b9b9b]"
                placeholder="Email"
              />
            </div>
            <div className="flex items-center gap-4 mt-4">
              <div className="font-medium text-white">User Since :</div>
              <div className="bg-[#2D2D2D] px-3 py-1 rounded-lg">
                07/05/2023
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="font-medium text-white">
                Current subscription till :
              </div>
              <div className="bg-[#2D2D2D] px-3 py-1 rounded-lg">
                07/05/2024
              </div>
            </div>
          </div>
          <div
            className="flex justify-center mt-8 w-full"
          >
            <button className="flex items-center gap-2 text-tertiary border border-tertiary rounded-lg px-4 py-1">
              <RiEditLine size={22} />
              <div>Edit Profile</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
