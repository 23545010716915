import axios from "axios";

export const getChannelsOfServer = (serverId, token) => {
  if (!serverId) throw new Error("Provide serverId value");
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/discord/server/${serverId}/channels`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const checkBotInServer = (serverId, token) => {
  if (!serverId) throw new Error("Provide serverId value");
  return axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/discord/bot/check/${serverId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const sendMessage = (values, token) =>
  axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/discord/channel/send-message`,
    values,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
