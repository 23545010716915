import React, { useEffect, useState } from "react";
import { userStore } from "../store/user";
import { toast } from "react-toastify";
import { checkBotInServer, getChannelsOfServer } from "../API/discord";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function DiscordInputs({ appendText, setAppendText }) {
  const [discordServerId, setDiscordServerId] = useState("");
  const [discordChannels, setDiscordChannels] = useState([]);
  const [showBotInvitationLink, setShowBotInvitationLink] = useState(false);
  const [discordChannelId, setDiscordChannelId] = useState("");
  const [loading, setLoading] = useState(false);
  const { userData, selectedDiscordChannel, setSelectedDiscordChannel } =
    userStore((state) => state);
  const { discordServers = [], currentDiscordServer } = userData || {};
  // const discordServerOptions = [
  //   { id: "", name: "select the server" },
  //   ...discordServers,
  // ].filter(
  //   (elem, index, arr) => arr.findIndex((obj) => obj.id === elem.id) === index
  // );
  // const cachedDiscordChannels = JSON.parse(
  //   localStorage.getItem("cachedDiscordChannels") || `{}`
  // );
  const gentradeToken = localStorage.getItem("gentradeToken");

  const setDataInLocalStorage = (name, key, value) => {
    const data = JSON.parse(localStorage.getItem(name) || `{}`);
    data[key] = value;
    localStorage.setItem(name, JSON.stringify(data));
  };

  useEffect(() => {
    // if (cachedDiscordChannels[discordServerId]?.length) {
    //   setDiscordChannels(cachedDiscordChannels[discordServerId]);
    //   setDiscordChannelId(cachedDiscordChannels[discordServerId]?.[0]?.id);
    //   setDataInLocalStorage(
    //     "btoMetaData",
    //     "discordChannelId",
    //     cachedDiscordChannels[discordServerId]?.[0]?.id
    //   );
    //   showBotInvitationLink && setShowBotInvitationLink(false);
    // }
    // else if (discordServerId) {
    //   (async () => {
    //     try {
    //       setLoading(true);
    //       const botCheckResponse = await checkBotInServer(
    //         discordServerId,
    //         gentradeToken
    //       );
    //       if (!botCheckResponse?.data?.isMember) {
    //         setShowBotInvitationLink(true);
    //         setLoading(false);
    //         return;
    //       }
    //       const channelsResponse = await getChannelsOfServer(
    //         discordServerId,
    //         gentradeToken
    //       );
    //       setDiscordChannels(channelsResponse.data);
    //       setDiscordChannelId(channelsResponse.data?.[0]?.id);
    //       setDataInLocalStorage(
    //         "cachedDiscordChannels",
    //         discordServerId,
    //         channelsResponse.data
    //       );
    //       setDataInLocalStorage(
    //         "btoMetaData",
    //         "discordChannelId",
    //         channelsResponse.data?.[0]?.id
    //       );
    //       setLoading(false);
    //       showBotInvitationLink && setShowBotInvitationLink(false);
    //     } catch (err) {
    //       console.log(err);
    //       toast(err.response?.data, {
    //         type: "error",
    //       });
    //       setLoading(false);
    //     }
    //   })();
    // } else {
    //   setShowBotInvitationLink(false);
    //   setDiscordChannels([]);
    // }

    if (discordServers?.length) {
      try {
        // let discordGuildId = discordServers[0].id;
        let discordGuildId = currentDiscordServer.id;
        console.log(discordGuildId, discordServers);
        setDiscordServerId(discordGuildId);
        setDataInLocalStorage("btoMetaData", "discordServerId", discordGuildId.toString());
        (async () => {
          const channelsResponse = await getChannelsOfServer(
            discordGuildId,
            gentradeToken
          );
          console.log(channelsResponse);
          // setDiscordChannels([channelsResponse.data?.[0]]);
          setDiscordChannels(channelsResponse.data);
          setDiscordChannelId(channelsResponse.data?.[0]?.id);
          setDataInLocalStorage(
            "btoMetaData",
            "discordChannelId",
            channelsResponse.data?.[0]?.id
          );
          setDataInLocalStorage(
            "cachedDiscordChannels",
            discordGuildId,
            channelsResponse.data
          );
        })();
      } catch (err) {
        console.log(err);
        toast(
          err.response?.data || "Something went wrong, please try again later",
          {
            type: "error",
          }
        );
      }
    }
  }, [discordServers]);

  return (
    <div className="flex mt-8 lg:mt-0 text-white flex-col md:flex-row gap-3">
      <div className="flex items-center gap-2">
        <label>Discord Servers</label>
        <select
          className="bg-[#424242] px-2 py-1 rounded-md"
          value={currentDiscordServer?.id}
          onChange={(e) => {
            setDiscordServerId(e.target.value);
            setDataInLocalStorage(
              "btoMetaData",
              "discordServerId",
              e.target.value
            );
          }}
        >
          <option value={currentDiscordServer?.id}>
            {currentDiscordServer?.name}
          </option>
        </select>
      </div>
      <div className="flex items-center gap-2">
        <label>Discord Channels</label>
        {loading ? (
          <div className="flex gap-2 items-center">
            Fetching
            <AiOutlineLoading3Quarters className="animate-spin" />{" "}
          </div>
        ) : (
          <select
            className="bg-[#424242] px-2 py-[2px] rounded-md"
            value={discordChannelId}
            onChange={(e) => {
              setDiscordChannelId(e.target.value);
              setSelectedDiscordChannel(e.target.value);
              setDataInLocalStorage(
                "btoMetaData",
                "discordChannelId",
                e.target.value
              );
            }}
          >
            {discordChannels.map((d, i) => (
              <option value={d.id} key={i}>
                {d.name}
              </option>
            ))}{" "}
          </select>
        )}
      </div>
      {/* {!showBotInvitationLink && discordChannels?.length ? (
        <div className="flex items-center gap-2">
          <label>Discord Channels</label>
          {loading ? (
            <div className="flex gap-2 items-center">
              Fetching
              <AiOutlineLoading3Quarters className="animate-spin" />{" "}
            </div>
          ) : (
            <select
              className="bg-[#424242] px-2 py-[2px] rounded-md"
              value={discordChannelId}
              onChange={(e) => {
                setDiscordChannelId(e.target.value);
                setDataInLocalStorage(
                  "btoMetaData",
                  "discordChannelId",
                  e.target.value
                );
              }}
            >
              {discordChannels.map((d, i) => (
                <option value={d.id} key={i}>
                  {d.name}
                </option>
              ))}{" "}
            </select>
          )}
        </div>
      ) : showBotInvitationLink ? (
        <a
          href={`https://discord.com/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&permissions=3072&scope=bot`}
          className="flex items-center gap-2 px-4 py-[2px] font-medium cursor-pointer bg-tertiary rounded-md text-black"
          target="_blank"
        >
          Invite Bot
        </a>
      ) : (
        ""
      )} */}
      <div>
        <span>Append Text : </span>
        <input
          value={appendText}
          onChange={(e) => {
            setDataInLocalStorage("btoMetaData", "appendText", e.target.value);
            setAppendText(e.target.value);
          }}
          className="bg-[#555555] px-2 py-[2px] outline-none text-white w-[210px] rounded-lg"
        />
      </div>
    </div>
  );
}

export default DiscordInputs;
